import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
import moment from 'moment'

export default {
    name: "TrasnferenciasPage",
    data() {
        let ss = new MainService();
        return {
            msg: "TrasnferenciasPage",
            ss: ss,
            ajax: {
                "url": ss.indexPersonaLiberacionActivos(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 't.id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'PersonaAsignacion', name: 'PersonaAsignacion', title: 'Asignado a' },
                { data: 'PersonaLiberacion', name: 'PersonaLiberacion', title: 'Liberado de' },
                { data: 'CodigoActivo', name: 'a.CodigoActivo', title: 'CodigoActivo' },
                { data: 'Descripcion', name: 'a.Descripcion', title: 'Activo' },
                { data: 'EstadoAsignacion', name: 'ea.EstadoAsignacion', title: 'Estado' },
                { data: 'FechaTransferencia', name: 't.FechaTransferencia', title: 'Fecha Liberación' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rols: [],
            personas: [],
            lactivos: [],
            estadoasignacions: [],
            reparticions: [],
            puestos: [],
            areas: [],
            multiselect: [],
            personaS: [],
            reparticionS: [],
            cargoS: [],
            areaS: [],
            activo: {},
            selectedPersona: null,
            selectedReparticion: null,
            selectedCargo: null,
            placeholderArea: null,
            searchActivo: '',
            searchPersona: '',
            searchReparticion: '',
            searchCargo: '',
            searchArea: '',
            placeholderPersona: '',
            placeholderReparticion: '',
            placeholderCargo: '',
            moment: moment,
            personaasignacionactivo: {},
            isLoading: false,
            errorBag: {},
            nuevo: 0,
            selectAll: false,
            activosAsignadosId: {},
            personaActivoGrupo: {},
            activosTranferidoId: {},
            activosTransferenaciaPersona: {},
            activosTransferidos: {},
            showTrasnferencia: {},
            showActivos: [],
            transferTo:0
        };
    },
    methods: {

        toggleAllCheckboxes() {
            for (let checkbox of this.activosAsignadosId) {
              checkbox.checked = this.selectAll;
            }
        },

        showCheckedItems() {
            this.checkedItems = this.checkboxes.filter(checkbox => checkbox.checked);
        },

        newTransferencia() {
            this.personaasignacionactivo = {};
            this.activo = {};
            this.personaasignacionactivo.EstadoAsignacion=4;
            this.nuevo=1;
            this.$refs['frm-persona-transferencia-activos'].show();
        },

        cancelPersonaAsignacionActivo() {
            if (this.personaasignacionactivo.id) {
                this.$refs['view-primera-asignacion'].show();
            }
            this.$refs['frm-primera-asignacion'].hide();
        },

        savePersonaAsignacionActivo() {

            console.log(this.activosAsignadosId);
            this.activosTransferidos.Activos = [];

            this.activosTransferidos.PersonaAsignacion = this.activosTransferenaciaPersona.PersonaAsignacion;
            this.activosTransferidos.idPersonaAsignacion = this.activosTransferenaciaPersona.idPersonaAsignacion;
            this.activosTransferidos.PersonaLiberacion = this.activosTransferenaciaPersona.PersonaLiberacion;
            this.activosTransferidos.idPersonaLiberacion = this.activosTransferenaciaPersona.idPersonaLiberacion;
            this.activosTransferidos.FechaAsignacion = this.activosTransferenaciaPersona.FechaAsignacion;                  
            this.activosTransferidos.FechaTransferencia = this.activosTransferenaciaPersona.FechaAsignacion;
            this.activosTransferidos.Descripcion = this.activosTransferenaciaPersona.Descripcion;
            this.activosTransferidos.EstadoAsignacionPersonaAsignacion = 4;
            this.activosTransferidos.EstadoLiberacionPersonaLiberacion = 5;
            this.activosTransferidos.EstadoAsignacion = 5;

            this.activosAsignadosId.forEach(element => {

                if (element.checked){
                    this.activosTransferidos.Activos.push(element);
                    
                }
            });
            


            console.log(this.activosTransferidos);


            this.ss.storePersonaTrasnferenciaActivos(this.activosTransferidos).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.$refs['frm-persona-transferencia-activos'].hide();
                        this.$refs['datatable-persona-transferencia-activos'].reload();
                    }
                    else{
                        console.log("no entro al if");
                    }
                });
                
        },

        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getActivo() {
            this.ss.listActivo().then(
                (result) => {
                    let response = result.data;
                    this.lactivos = response.data;
                    
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getEstadoAsignacion() {
            this.ss.listEstadoAsignacion().then(
                (result) => {
                    let response = result.data;
                    this.estadoasignacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                    this.ua=response.ua;
                    if (this.ua>0) {
                        this.activo.UnidadAcademica=this.ua;
                    } 
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getPuesto() {
            this.ss.listPuesto().then(
                (result) => {
                    let response = result.data;
                    this.puestos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getArea() {
            this.ss.listArea().then(
                (result) => {
                    let response = result.data;
                    this.areas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        showPersonaAsignacionActivo(id) {
            this.isLoading=true;

            this.ss.showPrimeraAsignacion(id).then(
                (result) => {
                    let response = result.data;
                    this.personaasignacionactivo = response.data;
                    console.log(this.personaasignacionactivo);
                    this.activo = this.personaasignacionactivo.activo;
                    this.$refs['view-primera-asignacion'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        editPersonaAsignacionActivo() {
            this.$refs['frm-primera-asignacion'].show();
            this.$refs['view-primera-asignacion'].hide();
            console.log(this.personaasignacionactivo);
        },

        getActivosIdPerson() {
            ///*
            this.ss.getActivosIdPersonTransferencias(this.personaActivoGrupo.id).then(
            //this.ss.getActivosIdPerson(id).then(
                (result) => {
                    let response = result.data;
                    // console.log(response);
                    this.activosAsignadosId = response.items;
                    this.activosAsignadosId.checked = false;
                    //console.log("activosAsignadosId id: " + this.activosAsignadosId.paaId);
                    //console.log("response id: " + response.items.id);
                }
            ).catch(error => {
                console.log(error);
            });
            //*/
        },

        showTransferencia(id){
            console.log(id);
            this.ss.showPersonaTrasnferenciaActivosL(id).then(
                    (result) => {
                        let response = result.data;
                        console.log(response);
                        this.showTrasnferencia = response.data;
                        this.showActivos = response.data2;
                        this.$refs['view-persona-transferencia-activos'].show();
                    }
                ).catch(error => {
                    console.log(error);
                });
        },

        cancelShowTransferencia(){
            this.$refs['view-persona-transferencia-activos'].hide();
        },

        generateActaAsignacion(){
            this.ss.generateActaAsignacionTransferencia(this.showTrasnferencia).then(
                (result) => {
                    let response = result.data;
                    console.log("Resultado : ", response.data);
                    //var urlFile = 'http://localhost:8000'+response.data.url;    
                    var urlFile = 'https://activosfijosbackend.net.emi.edu.bo'+response.data.url;                
                    window.open(urlFile, "abrir");  
                }
            ).catch(error => {
                console.log(error);
            })
        },

        generateActaLiberacion(){
            this.ss.generateActaLiberacionTransferencia(this.showTrasnferencia).then(
                (result) => {
                    let response = result.data;
                    console.log("Resultado : ", response.data);
                    //var urlFile = 'http://localhost:8000'+response.data.url;    
                    var urlFile = 'https://activosfijosbackend.net.emi.edu.bo'+response.data.url;                
                    window.open(urlFile, "abrir");  
                }
            ).catch(error => {
                console.log(error);
            })
        },


        newRol() {
            this.rol = {};
            this.$refs['frm-rol'].show();
        },
        showRol(id) {
            this.isLoading=true;
            this.ss.showRol(id).then(
                (result) => {
                    let response = result.data;
                    this.rol = response.data;
                    this.$refs['view-rol'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editRol() {
            this.$refs['frm-rol'].show();
            this.$refs['view-rol'].hide();
        },
        cancelRol() {
            if (this.rol.id) {
                this.$refs['view-rol'].show();
            }
            this.$refs['frm-rol'].hide();
        },
        saveRol() {
            this.ss.storeRol(this.rol).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-rol'].hide();
                    this.$refs['datatable-rol'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteRol() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyRol(this.rol)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-rol'].hide();
                                this.$refs['datatable-rol'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-PersonasTransferencias').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                if(data){
                    this.showTransferencia(data);
                }
                else{
                    alert('Ocurrio un error inesperado, Por favor intente nuevamente.');
                }
            });
        }
    },



    watch: {

        selectedPersona(value) {
            
            this.personaActivoGrupo.Persona = value.Persona;       
            this.personaActivoGrupo.id = value.id;

            this.activosTransferenaciaPersona.PersonaLiberacion = value.Persona;
            this.activosTransferenaciaPersona.idPersonaLiberacion = value.id;
            
            console.log("select persona value",value.id);
            console.log("personaActivoGrupo id "+this.personaActivoGrupo.id);
            console.log("personaActivoGrupo "+this.personaActivoGrupo.Persona);

            this.getActivosIdPerson();        
        },

        transferTo(value) {
            
            /*
            this.getActivosIdPerson();        
            */
            this.activosTranferidoId.Persona = value.Persona;       
            this.activosTranferidoId.id = value.id;

            this.activosTransferenaciaPersona.PersonaAsignacion = value.Persona;
            this.activosTransferenaciaPersona.idPersonaAsignacion = value.id;

            console.log("hi",value.id);
            console.log("transferTo "+this.activosTranferidoId.id);
            console.log("transferTo "+this.activosTranferidoId.Persona);
        },
        /*
        searchActivo(q) {
            this.ss.select22ActivoPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.activosS = res.data.items;
                } else {
                    this.activosS = [];
                }
            })
        },
        //*/
        searchActivo(q) {
            this.ss.select22ActivoPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.multiselect = res.data.items;
                } else {
                    this.multiselect = [];
                }
            })
        },
        searchPersona(q){
            this.ss.select22PersonaPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.personaS = res.data.items;
                } else {
                    this.personaS = [];
                }
            }) 
        },
        searchReparticion(q){
            this.ss.select22ReparticionPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.reparticionS= res.data.items;
                } else {
                    this.reparticionS = [];
                }
            }) 
        },
        searchCargo(q){
            this.ss.select22CargoPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.cargoS= res.data.items;
                } else {
                    this.cargoS = [];
                }
            }) 
        },
        searchArea(q){
            this.ss.select22AreaPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.areaS= res.data.items;
                } else {
                    this.areaS = [];
                }
            }) 
        }

    },




    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            this.getPersona();
            this.getActivo();
            this.getEstadoAsignacion();
            this.getReparticion();
            this.getPuesto();
            this.getArea();
        }
    }
};
