import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
import moment from 'moment'


export default {
    name: "PersonaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PersonaPage",
            ss: ss,
            ajax: {
                "url": ss.indexPersona(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Académica' },
                { data: 'Grado', name: 'Grado', title: 'Grado' },
                { data: 'Persona', name: 'Persona', title: 'Nombres y Apellidos' },
                { data: 'CI', name: 'CI', title: 'CI' },
                { data: 'Celular', name: 'Celular', title: 'Celular' },
                { data: 'Rol', name: 'Rol', title: 'Rol' },
                { data: 'email', name: 'email', title: 'Email' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            unidadAcademicas: [],
            departamentos: [],
            puestos: [],
            reparticions: [],
            rols: [],
            personas: [],
            persona: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            cargo: {},
            seccion: {},
            titulo: {},
            cargos: {},
            areas: {},
            tipo: {},
            moment: moment,
            personaasignacionactivo:{},
            lactivos: {},
            activo: {},
            posicion: 0,
            nuevo: 0

        };
    },
    methods: {
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        sincronizarRRHH(){
            this.$swal({
                title: "¿Esta seguro que desea actualizar al Personal?",
                text: "¡Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                //showCancelButton: true,
            })
                .then((sincro) => {
                    if (sincro.isConfirmed) {
                        this.ss.sincronizarPersona()
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                if ( response.success ) {
                                    this.$bvToast.toast(
                                        response.msg,
                                        {
                                            title: 'Correcto',
                                            variant: 'success',
                                            autoHideDelay: 5000
                                        }
                                    )
                                    this.$refs['datatable-persona'].reload();
                                } else {
                                    this.$bvToast.toast(
                                        'Problema al exportar los Usuarios, favor verificar los Datos',
                                        {
                                            title: 'Error',
                                            variant: 'danger',
                                            autoHideDelay: 5000
                                        }
                                    )
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        downloadDocumentoCustodia(text) {

            this.ss.printDocumentoCustodia({params: {'id': this.persona.id , 'texto': text, 'cargo': this.cargo, 'seccion': this.seccion}}).then(
                (result) => {
                    let response = result.data;
                    console.log("Resultado : ", response.data);
                    //var urlFile = 'http://localhost:8000'+response.data.url;    
                    var urlFile = 'https://activosfijosbackend.net.emi.edu.bo'+response.data.url;                
                    window.open(urlFile, "abrir");  
                }
            ).catch(error => {
                console.log(error);
            })
        },
        getActivo() {
            this.ss.listActivo().then(
                (result) => {
                    let response = result.data;
                    this.lactivos = response.data;
                    
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getArea() {
            this.ss.listArea().then(
                (result) => {
                    let response = result.data;
                    this.areas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getActivoDatos(id) {
            this.isLoading=true;
            this.ss.showActivo(id).then(
                (result) => {
                    let response = result.data;
                    this.activo = response.data;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editPersonaAsignacionActivo(posicion) {
            this.posicion=posicion;
            this.personaasignacionactivo=this.persona.referencia_asignacion[posicion];
            this.activo=this.persona.referencia_asignacion[posicion].activo;
            this.getPuesto();
            this.getActivo();
            this.getArea();
            this.$refs['frm-personaasignacionactivo'].show();
        },
        newPersonaAsignacionActivo() {
            this.getActivo();
            this.getReparticion();
            this.getPuesto();
            this.getArea();
            this.personaasignacionactivo = {};
            this.personaasignacionactivo.Persona = this.persona.id;
            this.personaasignacionactivo.EstadoAsignacion=2;
            this.activo = {};
            this.activo.Reparticion = this.persona.Reparticion;
            this.activo.Puesto = this.persona.Puesto;
            this.nuevo=1;
            this.$refs['frm-personaasignacionactivo'].show();
        },
        savePersonaAsignacionActivo() {
            this.ss.storePersonaAsignacionActivo(this.personaasignacionactivo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    if (response.success) {
                    this.$bvToast.toast(
                        response.msg,
                        {   title: 'Correcta Asignacion al Personal',
                            variant: 'success',
                            autoHideDelay: 5000 })
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                   // this.nuevo=0;
                    //this.activo.id=this.personaasignacionactivo.Activo;
                        this.ss.storeActivo(this.activo).then(
                            (result) => {
                                console.log(result);
                                let response = result.data;
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcta actualización del Activo',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                console.log(response);
                            })
                            .catch((error) => {
                                this.errorBag = error.response.data.errors;
                                this.$bvToast.toast(
                                    'Problema al Guardar el Registro, favor verificar los Datos',
                                    {   title: 'Error',
                                        variant: 'danger',
                                        autoHideDelay: 5000 } )
                            });
                            this.$refs['frm-personaasignacionactivo'].hide();
                            //this.$refs.tablaactivos.refresh();
                            this.showPersona(this.persona.id);
                    }else{
                        this.$bvToast.toast(
                            response.msg,
                            {   title: 'Asignación Observada',
                                variant: 'warning',
                                autoHideDelay: 5000 })
                    }
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {   title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000 } )
                });
        },
        cargoDocumentoCustodia(texto){
            this.cargo=0;
            this.seccion=0;
            //this.getCargos(vm.usuario.id);
            if (texto=='Custodia') {
                this.titulo='Acta de Asignación';
            } else {
                this.titulo='Acta de Liberación';                
            }
            this.tipo=texto;
            this.ss.urlListCargoPersona({params: {'id': this.persona.id}}).then(
                (result) => {
                    let response = result.data;
                    this.cargos = response.data; 
                    this.ss.urlListAreaPersona({params: {'id': this.persona.id}}).then(
                        (result) => {
                            let response = result.data;
                            this.areas = response.data; 
                            this.$refs['frm-cargo'].show();
                        }
                    ).catch(error => {
                        console.log(error);
                    })
                }
            ).catch(error => {
                console.log(error);
            })
        },
        cancelDownload() {
            this.$refs['frm-cargo'].hide();
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getDepartamento() {
            this.ss.listDepartamento().then(
                (result) => {
                    let response = result.data;
                    this.departamentos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPuesto() {
            this.ss.listPuesto({params:{'UnidadAcademica':this.persona.UnidadAcademica}}).then(
                (result) => {
                    let response = result.data;
                    this.puestos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion() {
            this.ss.listReparticion({params:{'UnidadAcademica':this.persona.UnidadAcademica}}).then(
                (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                    this.getPuesto();
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getRol() {
            this.ss.listRol().then(
                (result) => {
                    let response = result.data;
                    this.rols = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newPersona() {
            this.persona = {};
            this.$refs['frm-persona'].show();
        },
        cancelPersonaAsignacionActivo() {
            this.$refs['frm-personaasignacionactivo'].hide();
        },
        showPersona(id) {
            this.isLoading=true;
            this.ss.showPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.persona = response.data;
                    this.$refs['view-persona'].show();
                    this.isLoading=false;
                    this.getReparticion();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editPersona() {
            this.$refs['frm-persona'].show();
            this.$refs['view-persona'].hide();
        },
        cancelPersona() {
            if (this.persona.id) {
                this.$refs['view-persona'].show();
            }
            this.$refs['frm-persona'].hide();
        },
        savePersona() {
            this.ss.storePersona(this.persona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    if (response.success) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        console.log(response);
                        this.$refs['frm-persona'].hide();
                        this.showPersona(this.persona.id);
                        this.$refs['datatable-persona'].reload();    
                    } else {
                        this.$bvToast.toast(
                            
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )    
                    }
                    //this.$refs['view-consulta'].show(); //para volver al modal
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePersona() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyPersona(this.persona)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-persona'].hide();
                                this.$refs['datatable-persona'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-persona'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-persona'].show();
        },
        changePassword() {
            this.password.Persona = this.persona.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-persona'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        draw() {
            window.$('.btn-datatable-Persona').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersona(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getUnidadAcademica();
            this.getRol();
            this.getDepartamento();
            this.getPuesto();
            this.getReparticion();
            
        }
    }
};
