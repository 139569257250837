import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
import moment from 'moment'

export default {
    name: "PrimeraAsignacionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "RolPage",
            ss: ss,
            ajax: {
                "url": ss.indexPrimeraAsignacion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'pa.id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'CodigoActivo', name: 'a.CodigoActivo', title: 'CodigoActivo' },
                { data: 'Descripcion', name: 'a.Descripcion', title: 'Activo' },
                { data: 'EstadoAsignacion', name: 'ea.EstadoAsignacion', title: 'Estado' },
                { data: 'FechaAsignacion', name: 'pa.FechaAsignacion', title: 'Fecha Asignacion' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rols: [],
            personas: [],
            lactivos: [],
            estadoasignacions: [],
            reparticions: [],
            puestos: [],
            areas: [],
            multiselect: [],
            personaS: [],
            reparticionS: [],
            cargoS: [],
            areaS: [],
            ActivosAsignados: [],
            activosList: [],
            activo: {},
            codigoActivo: '',
            selectedPersona: null,
            selectedReparticion: null,
            selectedCargo: null,
            placeholderArea: null,
            searchActivo: '',
            searchPersona: '',
            searchReparticion: '',
            searchCargo: '',
            searchArea: '',
            placeholderPersona: '',
            placeholderReparticion: '',
            placeholderCargo: '',
            moment: moment,
            personaasignacionactivo: {},
            isLoading: false,
            errorBag: {},
            nuevo: 0
        };
    },
    methods: {

        newPrimeraAsignacion() {
            this.personaasignacionactivo = {};
            this.activo = {};
            this.personaasignacionactivo.EstadoAsignacion=2;
            this.nuevo=1;
            this.$refs['frm-primera-asignacion'].show();
        },

        cancelPersonaAsignacionActivo() {
            if (this.personaasignacionactivo.id) {
                this.$refs['view-primera-asignacion'].show();
            }
            this.$refs['frm-primera-asignacion'].hide();
        },

        savePrimeraAsignacion() {
            if(this.multiselect.length > 0){
                for (let i = 0; i < this.multiselect.length; i++) {
                    console.log('i: ', this.multiselect[i].id)
                    //
                    if (this.multiselect[i].checked) {                   
                    this.personaasignacionactivo.Activo=this.multiselect[i].id;
                    this.personaasignacionactivo.Persona=this.selectedPersona.id;
                    this.ss.storePrimeraAsignacion(this.personaasignacionactivo).then(
                        (result) => {
                            console.log(result);
                            let response = result.data;
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcta Asignacion al Personal',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            console.log(response);
                            console.log("success",response.success);
                            this.$refs['frm-primera-asignacion'].hide();
                            this.$refs['datatable-primera-asignacion'].reload();
                           
                        });
                    }
                    ///    
                }
            }else{
            this.personaasignacionactivo.Activo=this.selectedActivo.id;
            this.personaasignacionactivo.Persona=this.selectedPersona.id;
            console.log("entro");
            console.log(this.personaasignacionactivo);

            this.ss.storePrimeraAsignacion(this.personaasignacionactivo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcta Asignacion al Personal',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    // this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    console.log("success",response.success);
                    this.$refs['frm-primera-asignacion'].hide();
                    this.$refs['datatable-primera-asignacion'].reload();
                    
                });                
            }        
        },

        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getActivo() {
            this.ss.listActivo().then(
                (result) => {
                    let response = result.data;
                    this.lactivos = response.data;
                    
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getEstadoAsignacion() {
            this.ss.listEstadoAsignacion().then(
                (result) => {
                    let response = result.data;
                    this.estadoasignacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                    this.ua=response.ua;
                    if (this.ua>0) {
                        this.activo.UnidadAcademica=this.ua;
                    } 
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getPuesto() {
            this.ss.listPuesto().then(
                (result) => {
                    let response = result.data;
                    this.puestos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getArea() {
            this.ss.listArea().then(
                (result) => {
                    let response = result.data;
                    this.areas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        showPersonaAsignacionActivo(id) {
            this.isLoading=true;

            this.ss.showPrimeraAsignacion(id).then(
                (result) => {
                    let response = result.data;
                    this.ActivosAsignados = response.data;
                    console.log(this.ActivosAsignados);
                    this.$refs['view-primera-asignacion'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        editPersonaAsignacionActivo() {
            this.$refs['frm-primera-asignacion'].show();
            this.$refs['view-primera-asignacion'].hide();
        },

        deletePersonaAsignacionActivo() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyPrimeraAsignacion(this.personaasignacionactivo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-primera-asignacion'].hide();
                                this.$refs['datatable-primera-asignacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        cargoDocumentoCustodia(){
            this.ss.generateActaAsignacion(this.personaasignacionactivo).then(
                (result) => {
                    let response = result.data;
                    console.log("Resultado : ", response.data);
                    //var urlFile = 'http://localhost:8000'+response.data.url;    
                    var urlFile = 'https://activosfijosbackend.net.emi.edu.bo'+response.data.url;                
                    window.open(urlFile, "abrir");  
                }
            ).catch(error => {
                console.log(error);
            })
        },


        agregarActivoPersona(){
            this.$refs['frm-Agregar-Activos-Persona'].show();
        },


        newRol() {
            this.rol = {};
            this.$refs['frm-rol'].show();
        },
        showRol(id) {
            this.isLoading=true;
            this.ss.showRol(id).then(
                (result) => {
                    let response = result.data;
                    this.rol = response.data;
                    this.$refs['view-rol'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editRol() {
            this.$refs['frm-rol'].show();
            this.$refs['view-rol'].hide();
        },
        cancelRol() {
            if (this.rol.id) {
                this.$refs['view-rol'].show();
            }
            this.$refs['frm-rol'].hide();
        },
        saveRol() {
            this.ss.storeRol(this.rol).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-rol'].hide();
                    this.$refs['datatable-rol'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteRol() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyRol(this.rol)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-rol'].hide();
                                this.$refs['datatable-rol'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        getListActivos(){
            this.ss.showActivoByCodigoActivo(this.codigoActivo).then(
                (result) => {
                    let response = result.data;
                    this.activosList = response.data;
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Verificar el codigo de Activo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        asignarAtivosPersona(idActivo){
            const asignacion = {};
            asignacion.Activo = idActivo; 
            asignacion.Persona = this.ActivosAsignados[0].persona.id;
            asignacion.EstadoAsignacion = 2;
            console.log(asignacion);
            this.ss.storePrimeraAsignacion(asignacion).then(
                (result) => {
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcta Asignacion al Personal',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['frm-Agregar-Activos-Persona'].hide();
                    this.showPersonaAsignacionActivo(asignacion.Persona);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        draw() {
            window.$('.btn-datatable-Primera-Asignacion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;

                if(data == null){
                    this.$swal.fire(
                        'Oops...',
                        'Por favor intente nuevamente.',
                        'error'
                      )
                    return;
                }
                else{
                    this.showPersonaAsignacionActivo(data);
                }

            });
        }
    },



    watch: {
        /*
        searchActivo(q) {
            this.ss.select22ActivoPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.activosS = res.data.items;
                } else {
                    this.activosS = [];
                }
            })
        },
        //*/
        searchActivo(q) {
            this.ss.select22ActivoPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.multiselect = res.data.items;
                } else {
                    this.multiselect = [];
                }
            })
        },
        searchPersona(q){
            this.ss.select22PersonaPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.personaS = res.data.items;
                } else {
                    this.personaS = [];
                }
            }) 
        },
        searchReparticion(q){
            this.ss.select22ReparticionPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.reparticionS= res.data.items;
                } else {
                    this.reparticionS = [];
                }
            }) 
        },
        searchCargo(q){
            this.ss.select22CargoPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.cargoS= res.data.items;
                } else {
                    this.cargoS = [];
                }
            }) 
        },
        searchArea(q){
            this.ss.select22AreaPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.areaS= res.data.items;
                } else {
                    this.areaS = [];
                }
            }) 
        }

    },




    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            this.getPersona();
            this.getActivo();
            this.getEstadoAsignacion();
            this.getReparticion();
            this.getPuesto();
            this.getArea();
        }
    }
};
