import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PersonaActivosPage",
    data() {
        let ss = new MainService();
        return {
            msg: "RolPage",
            ss: ss,
            ajax: {
                "url": ss.indexPersonasActivos(),
                headers: ss.getToken(),
                data: function (d) {
                    d.Gestion = 0;
                }
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'idPersona', name: 'idPersona', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'Persona', title: 'Persona' },
                { data: 'CodigoActivo', name: 'a.CodigoActivo', title: 'Codigo Activo' },
                { data: 'Descripcion', name: 'a.Descripcion', title: 'Descripcion' },
                { data: 'EstadoAsignacion', name: 'ea.EstadoAsignacion', title: 'Estado Asignacion' },
                { data: 'updated_at', name: 'pa.updated_at', title: 'Fecha Asignacion' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            activos: [],
            activo: {},
            activosAsignados: [],
            activosTransferidosSalida: [],
            activosTransferidosEntrada: [],
            activosLiberados: [],
            gestiones: [],
            persona: {},
            personaasignacionactivo : {},
            gestion: {},
            data: {},
            anioActual: 0,
            isLoading: false,
            errorBag: {},

            activosList: [],
            codigoActivo: '',

            personaNombre: '',
            personasList: [],

            asignacionDeActivos: {},
            personaInfo: {},
            activosInfo: [],

            reparticiones: {},
            puestos: {},
            areas: {},

        };
    },
    methods: {

        showActivosPersonaDetalles(value) {
            this.isLoading=true;
            this.ss.showPersonaActivoDetalle(value).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.persona = response.persona;
                    this.activos = response.activos;
                    this.activosAsignados = response.ActivosAsignados;
                    this.activosTransferidosSalida = response.activosTransferidosSalida;
                    this.activosTransferidosEntrada = response.activosTransferidosEntrada;
                    this.activosLiberados = response.activosLiberados;
                    this.$refs['view-detalle'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            }); 
        },

        agregarActivoPersona(){
            this.asignacionDeActivos.Activos = [];
            this.activosInfo = [];
            this.personaInfo = {};
            this.$refs['frm-Agregar-Activos-Persona'].show();
        },

        getListActivos(){
            if (this.codigoActivo.length > 3){
                this.ss.showActivoByCodigoActivo(this.codigoActivo).then(
                    (result) => {
                        let response = result.data;
                        this.activosList = response.data;
                    })
                    .catch((error) => {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(
                            'Verificar el codigo de Activo',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });
            }
            else{
                this.activosList = [];
            }
        },

        getListPersona(){
            if (this.personaNombre.length > 3){
                this.ss.showPersonaByNombres(this.personaNombre).then(
                    (result) => {
                        let response = result.data;
                        this.personasList = response.data;
                    })
                    .catch((error) => {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(
                            'Verificar el nombre de la persona',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });
            }
            else{
                this.personasList = [];
            }
        },

        selectPersona(item){
            this.asignacionDeActivos.Persona = item;
            this.personaInfo = item;
            this.asignacionDeActivos.UnidadAcademica = this.personaInfo.unidad_academica.id;
        },

        agregarActivos(item){
                if (Object.keys(this.personaInfo).length === 0) {
                    this.$swal.fire(
                        'Oops...',
                        'Se debe seleccionar a una persona previamente.',
                        'warning'
                    );
                    return;
                }
            
                const validar = {
                    Persona: this.personaInfo.id,
                    Activo: item.id
                };
            
                this.ss.verificarAsignacionActivo(validar)
                    .then((result) => {
                        let response = result.data;
                        if (response.success) {
                            if (response.data == null) {
                                this.$swal.fire(
                                    'Oops...',
                                    response.msg,
                                    'warning'
                                );
                            } else {
                                this.$swal.fire(
                                    'Oops...',
                                    response.msg + '<br>' + response.data.Persona,
                                    'warning'
                                );
                            }
                        } else {
                            if (!this.asignacionDeActivos.Activos.some(element => element.id === item.id)) {
                                this.asignacionDeActivos.Activos.push(item);
                                this.activosInfo.push(item);
                                console.log(this.activosInfo);
                            } else {
                                this.$swal.fire(
                                    'Oops...',
                                    'El activo ya se encuentra asignado en la lista.',
                                    'warning'
                                );
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(
                            'Verificar datos de la asignacion',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        );
                    });
            
        },

        quitarActivos(index){
            this.asignacionDeActivos.Activos.splice(index, 1);
            this.activosInfo.splice(index, 1);
        },

        saveAsignacion(){

            if (Object.keys(this.personaInfo).length === 0) {
                this.$swal.fire(
                    'Oops...',
                    'Se debe seleccionar a una persona previamente.',
                    'warning'
                );
                return;
            }

            if (Object.keys(this.asignacionDeActivos.Activos).length === 0) {
                this.$swal.fire(
                    'Oops...',
                    'Se debe haber un activo en lista minimamente.',
                    'warning'
                );
                return;
            }

            if (!this.asignacionDeActivos.Reparticion) {
                this.$swal.fire(
                    'Oops...',
                    'Por favor seleccione una reparticion.',
                    'warning'
                );
                return;
            }

            if (!this.asignacionDeActivos.Cargo) {
                this.$swal.fire(
                    'Oops...',
                    'por favor selecciones un cargo.',
                    'warning'
                );
                return;
            }

            if (!this.asignacionDeActivos.Area) {
                this.$swal.fire(
                    'Oops...',
                    'Por favor seleccione un Area/seccion.',
                    'warning'
                );
                return;
            }

            this.asignacionDeActivos.EstadoAsignacion = 2;
            console.log(this.asignacionDeActivos);
            this.$swal.fire({
                title: "Estas Seguro ?",
                text: "No podras revertir esta accion !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, Asignar!"
              }).then((result) => {
                if (result.isConfirmed) {

                    let timerInterval;
                    this.$swal.fire({
                      title: "Realizando las asignaciones!",
                      html: "Por favor, espere <b></b>.",
                      timer: 3000,
                      timerProgressBar: true,
                      didOpen: () => {
                        this.$swal.showLoading();
                        const timer = this.$swal.getPopup().querySelector("b");
                        timerInterval = setInterval(() => {
                          timer.textContent = `${this.$swal.getTimerLeft()}`;
                        }, 100);

                        this.ss.storePersonasActivos(this.asignacionDeActivos).then(
                            (result) => {
                                let response = result.data;
                                console.log(response);
                            }
                        ).catch(error => {
                            console.log(error);
                        });
                        this.$refs['frm-Agregar-Activos-Persona'].hide();
                      },
                      willClose: () => {
                        clearInterval(timerInterval);
                      }
                    });
                }
              });
        },

        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getPuesto() {
            this.ss.listPuesto().then(
                (result) => {
                    let response = result.data;
                    this.puestos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getArea() {
            this.ss.listArea().then(
                (result) => {
                    let response = result.data;
                    this.areas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getGestiones(){
            var fechaActual = new Date();
            var añoActual = fechaActual.getFullYear();
            this.gestion = añoActual;
            this.ss.getGestiones().then(
                (result) => {
                    let response = result.data;
                    this.gestiones = response.data;
                    console.log(this.gestiones);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        async getActivosGestion(){
            this.isLoading = true;

            let gestion = this.gestion;
            this.ajax.data = function(d){
                d.Gestion = gestion;
            }

            await new Promise(resolve => setTimeout(resolve, 2000));
            this.$refs['datatable-PersonaActivos'].reloadAjax(this.ajax);

            this.isLoading = false;
            console.log(this.gestion);
            
        },

        generateActaAsignacion(){
            var fechaActual = new Date();
            this.anioActual = fechaActual.getFullYear();

            const reporte = {
                idPersona: this.persona.id,
                gestion: this.anioActual
            }

            console.log(reporte);
            this.ss.generateActaAsignacionActivosPersona(reporte).then(
                (result) => {
                    let response = result.data;
                    console.log("Resultado : ", response.data);
                    //var urlFile = 'http://localhost:8000'+response.data.url;    
                    var urlFile = 'https://activosfijosbackend.net.emi.edu.bo'+response.data.url;                
                    window.open(urlFile, "abrir");  
                }
            ).catch(error => {
                console.log(error);
            })
        },
    

        draw() {
            window.$('.btn-datatable-PersonaActivos').on('click', (evt) => {
                const data = window.$(evt.target)[0].getAttribute('idPersona');

                if(data == null){
                    this.$swal.fire(
                        'Oops...',
                        'Por favor intente nuevamente.',
                        'error'
                      )
                    return;
                }
                else{
                    this.data.id = data;
                    if(this.gestion){
                        this.data.Gestion = this.gestion;
                    }else{
                        this.data.Gestion = 2024;
                    }
                    console.log(this.data);
                    this.showActivosPersonaDetalles(this.data);
                }
                
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }
        else{
            this.getGestiones();
            var fechaActual = new Date();
            this.anioActual = fechaActual.getFullYear();
            this.asignacionDeActivos.Activos = [];
            this.activosInfo = [];
            this.getReparticion();
            this.getPuesto();
            this.getArea();

        }
    }
};
