import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
import moment from 'moment'

export default {
    name: "ActivoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ActivoPage",
            ss: ss,
            ajax: {
                "url": ss.indexActivo(),
                data: function (d) {
                    d.Fungible = 'Fungible';
                },
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'a.id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Académica' },
                { data: 'CodigoActivo', name: 'a.CodigoActivo', title: 'Código Activo' },
                { data: 'Descripcion', name: 'a.Descripcion', title: 'Descripción' },
                { data: 'FechaIncorporacion', name: 'a.FechaIncorporacion', title: 'Fecha Incorporación' },
                { data: 'GrupoActivo', name: 'ga.GrupoActivo', title: 'Grupo Activo' },
                { data: 'EstadoActivo', name: 'ea.EstadoActivo', title: 'Estado Activo' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            unidadAcademicas: [],
            puestos: [],
            reparticions: [],
            areas: [],
            grupoActivos: [],
            estadoActivo: [],
            proveedors: [],
            activos: [],
            lactivos: [],
            activo: {},
            isLoading: false,
            errorBag: {},
            asignacionActivo: {},
            personas: {},
            isLoadingFile: false,
            moment: moment

        };
    },
    methods: {
        loadFile(input) {
            this.isLoadingFile = true;
            console.log('Archivo:',input);
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.activo.DocumentoBaja = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        // showReferencia(){
        //     if(this.activo.Pertenece=='1'){
        //          this.$refs['#ReferenciaActivo'].show();
                 
        //      }else{
        //         this.$refs['#ReferenciaActivo'].hide();
        //         this.activos ={};
        //      }
        // },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion() {
            console.log("estoy aca");
            this.ss.listReparticion({params:{'UnidadAcademica':this.activo.UnidadAcademica}}).then(
                (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                    this.getPuesto();
                    this.getPersona();
                    this.getArea();
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPuesto() {
            this.ss.listPuesto({params:{'UnidadAcademica':this.activo.UnidadAcademica}}).then(
                (result) => {
                    let response = result.data;
                    this.puestos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getArea() {
            this.ss.listArea({params:{'UnidadAcademica':this.activo.UnidadAcademica}}).then(
                (result) => {
                    let response = result.data;
                    this.areas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getGrupoActivo() {
            this.ss.listGrupoActivo().then(
                (result) => {
                    let response = result.data;
                    this.grupoActivos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstadoActivo() {
            this.ss.listEstadoActivo().then(
                (result) => {
                    let response = result.data;
                    this.estadoActivo = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getProveedor() {
            this.ss.listProveedor().then(
                (result) => {
                    let response = result.data;
                    this.proveedors = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getActivo() {
            this.ss.listActivo().then(
                (result) => {
                    let response = result.data;
                    this.lactivos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPersona() {
            this.ss.listPersona({params:{'UnidadAcademica':this.activo.UnidadAcademica}}).then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                    
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newActivo() {
            this.activo = {};
            this.asignacionActivo = {};
            this.$refs['frm-activo'].show();
        },
        showActivo(id) {
            this.isLoading=true;
            this.ss.showActivo(id).then(
                (result) => {
                    let response = result.data;
                    this.activo = response.data;
                    if (response.asignacion) {
                        this.asignacionActivo= response.asignacion;
                    } else {
                        this.asignacionActivo = {};
                    }
                    this.$refs['view-activo'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editActivo() {
            this.$refs['frm-activo'].show();
            this.$refs['view-activo'].hide();
        },
        cancelActivo() {
            if (this.activo.id) {
                this.$refs['view-activo'].show();
            }
            this.$refs['frm-activo'].hide();
        },
        saveActivo() {
            this.activo.Fungible=true;
            this.ss.storeActivo(this.activo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correctamente se guardo el Activo',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.activo= response.data;
                    var today = new Date();
                    let mes;
                    if ((today.getMonth()+1)>9) {
                        mes=(today.getMonth()+1);
                    } else {mes='0'+(today.getMonth()+1);}
                    let Fecha = today.getFullYear()+'-'+mes+'-'+today.getDate();

                    if(this.asignacionActivo.Persona){
                        this.ss.storePersonaAsignacionActivo({'id':this.asignacionActivo.id,'Persona':this.asignacionActivo.Persona, 'Activo':this.activo.id, 'EstadoAsignacion':1, 'FechaAsignacion':Fecha}).then(
                            (result) => {
                                console.log(result);
                                let response = result.data;
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcta Asignacion al Personal',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                console.log(response);
                            })
                            .catch((error) => {
                                this.errorBag = error.response.data.errors;
                                this.$bvToast.toast(
                                    'Problema al Guardar el Registro, favor verificar los Datos',
                                    {
                                        title: 'Error',
                                        variant: 'danger',
                                        autoHideDelay: 5000
                                    }
                                )
                            });
                    }
                    this.$refs['frm-activo'].hide();
                    this.$refs['datatable-activo'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteActivo() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyActivo(this.activo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-activo'].hide();
                                this.$refs['datatable-activo'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        bajaActivo(tipo){
            if (tipo==0) {
                this.$swal({
                    title: "¿Esta seguro que desea dar de baja el activo?",
                    text: "Esta accion es irreversible!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete.isConfirmed) {
                            this.activo.EstadoActivo=4;
                            this.ss.storeActivo(this.activo)
                                .then((result) => {
                                    let response = result.data;
                                    console.log(response);
                                    this.$bvToast.toast(
                                        'El activo fue dado de baja, suba su respaldo de la baja',
                                        {
                                            title: 'Activo dado de BAJA',
                                            variant: 'success',
                                            autoHideDelay: 5000
                                        }
                                    )
                                    this.reload();
                                })
                                .catch(error => {
                                    console.log(error);
                               })
                        } else {
                            //swal("Your imaginary file is safe!");
                        }
                    });
            } 
            if (tipo==1) {
                this.ss.storeActivo(this.activo)
                    .then((result) => {
                        let response = result.data;
                        console.log(response);
                        this.$bvToast.toast(
                            'Respaldo actualizado',
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['frm-baja'].hide();
                        this.$refs['view-activo'].hide();
                        this.$refs['datatable-activo'].reload();
                    })
                    .catch(error => {
                       console.log(error);
                    })
            } 
            if (tipo==2) {
                    this.$refs['frm-baja'].hide();
                    this.$refs['view-activo'].hide();
                    this.$refs['datatable-activo'].reload();
            } 
        },
        draw() {
            window.$('.btn-datatable-Activo').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showActivo(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getUnidadAcademica();
            this.getReparticion();
            //this.getPuesto();
            //this.getArea();
            this.getGrupoActivo();
            this.getEstadoActivo();
            this.getProveedor();
            this.getActivo();
            //this.getPersona();
        }
    }
};
