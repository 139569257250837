import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
import moment from 'moment'

export default {
    name: "ActivoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ActivoPage",
            ss: ss,
            ajax: {
                "url": ss.indexActivoPrincipal(),
                headers: ss.getToken(),
                data: function (d) {
                    d.idUnidadAcademica = 0;
                }

            },
           
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },

                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Académica' },
                
                { data: 'CodigoActivo', name: 'CodigoActivo', title: 'Código Activo' },
                // { data: 'TipodeBien', name: 'a.TipodeBien', title: 'Tipo de Bien' },
                { data: 'Descripcion', name: 'Descripcion', title: 'Descripción' },
                { data: 'FechaIncorporacion', name: 'FechaIncorporacion', title: 'Fecha Incorporación' ,searchable: false},
                // { data: 'GrupoActivo', name: 'ga.GrupoActivo', title: 'Grupo Activo' },
                // { data: 'EstadoActivo', name: 'EstadoActivo', title: 'Estado Activo' , searchable: false},
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],


            /* Reporte Reparticion */
            ajaxReporteReparticion: {
                "url": ss.indexReporteReparticion(),
                headers: ss.getToken(),
            },
            
            columnsReporteReparticion: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Académica' },
                { data: 'Reparticion', name: 'r.Reparticion', title: 'Repartición' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],


            unidadAcademicas: [],
            unidadAcademica: [],
            puestos: [],
            reparticions: [],
            reparticion:{},
            areas: [],
            grupoActivos: [],
            estadoActivo: [],
            proveedors: [],
            activos: [],
            lactivos: [],
            personalRepartivion: [],
            activo: {},
            isLoading: false,
            errorBag: {},
            asignacionActivo: {},
            personas: {},
            file:{},
            isLoadingFile: false,
            moment: moment,
            searchActivo: '',
            selectedActivo: null,
            personaasignacionactivos: [],
            personaasignacionactivo: {},
            activosS: [],
            personaS: [],
            //persona search
            selectedPersona: '',
            placeholderPersona: '',
            placeholderActivo: '',
            searchPersona: '',
            nuevo:0,
            unidadAcademicaFill:0,
            idReporteActivoReparticion:0,
            anoReporteActivoReparticion:0,
            estadoasignacions:[],
            //search Reparticion
            reparticionS: [],
            searchReparticion: '',
            placeholderReparticion:'',
            selectedReparticion: '',
            //search Cargo
            cargoS: [],
            searchCargo: '',
            placeholderCargo:'',
            selectedCargo: '',
            //search Area
            areaS: [],
            searchArea: '',
            placeholderArea:'',
            selectedArea: '',
        };
    },
    methods: {

        /* Filtrado por unidad academica */

        async fillUnidadAcademica(){
        this.isLoading=true;
            let id = this.unidadAcademicaFill;
            this.ajax.data = function(d){
                d.idUnidadAcademica = id;
            }
        await new Promise(resolve => setTimeout(resolve, 3000));

        this.$refs['datatable-activo'].reloadAjax(this.ajax);
        this.isLoading=false;
    },


        //REPORTES REPARTICION
        openReporteReparticionView(){
            this.$refs['view-reporte-reparticion'].show();
        },

        modalAsignar(){
            this.$refs['view-activo'].hide();
            this.$refs['asignarActivo'].show();
            this.isLoading=false;
        },
        modalTransferir(){
            this.$refs['view-activo'].hide();
            this.$refs['trasnferirActivo'].show();
        },
        cancelPersonaAsignacionActivo(){
            this.$refs['asignarActivo'].hide();
            this.$refs['view-activo'].show();


        },

        downloadDocumentoCustodia(text) {

            this.ss.printDocumentoCustodia({params: {'id': this.activo.ultimasignacion.Persona, 'texto': text, 'cargo': this.activo.Puesto, 'seccion': this.activo.Area}}).then(
                (result) => {
                    let response = result.data;
                    console.log("Resultado : ", response.data);
                    //var urlFile = 'http://localhost:8000'+response.data.url;
                    var urlFile = 'https://activosfijosbackend.net.emi.edu.bo'+response.data.url;                    
                    window.open(urlFile, "abrir");  
                }
            ).catch(error => {
                console.log(error);
            })
        },

        loadFile(input) {
            this.isLoadingFile = true;
            console.log('Archivo:', input);
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.activo.DocumentoBaja = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        // showReferencia(){
        //     if(this.activo.Pertenece=='1'){
        //          this.$refs['#ReferenciaActivo'].show();

        //      }else{
        //         this.$refs['#ReferenciaActivo'].hide();
        //         this.activos ={};
        //      }
        // },
        etiquetaActivo() {
            this.ss.etiquetaActivo(this.activo).then(
                result => {
                    console.log(result);
                    let response = result.data;
                    if (response.success) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        var a = document.createElement("a");
                        a.href = response.data.url;
                        a.target = '_blank';
                        a.click();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                    this.errorBag = {};
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log(error);
                    this.$bvToast.toast(
                        'Error al generar el reporte',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        incorporacionActivo() {
            this.ss.incorporacionActivo(this.activo).then(
                result => {
                    console.log(result);
                    let response = result.data;
                    if (response.success) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        var a = document.createElement("a");
                        a.href = response.data.url;
                        a.target = '_blank';
                        a.click();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                    this.errorBag = {};
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log(error);
                    this.$bvToast.toast(
                        'Error al generar el reporte',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                    console.log('unidad academica nombre '+this.unidadAcademicas.UnidadAcademica);
                }
            ).catch(error => {
                console.log(error);
            });
            
        },
        getReparticion() {           
            this.ss.listReparticion({ params: { 'UnidadAcademica': this.activo.UnidadAcademica } }).then(
                 (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                    this.getPuesto();
                    this.getPersona();
                    this.getArea();
                    this.getUnidadAcademica();
                }
            ).catch(error => {
                console.log(error);
            });
            
            this.ss.showUnidadAcademica( this.activo.UnidadAcademica ).then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademica = response.data;
                    console.log('this.unidadAcademica.NumeroUA '+ this.unidadAcademica.NumeroUA);
                   
                }
            ).catch(error => {
                console.log(error);
            });         

            this.ss.contarActivo( this.activo.UnidadAcademica ).then(
                (result) => {
                    let response = result.data;                   

                    if (response>9999) {
                        this.activo.CodigoActivo = this.unidadAcademica.NumeroUA + '-' + (response + 1);        
                    } else {
                        if (response>999) {
                            this.activo.CodigoActivo =  this.unidadAcademica.NumeroUA + '-0' + (response + 1);        
                        } else {
                            this.activo.CodigoActivo =  this.unidadAcademica.NumeroUA + '-00' + (response + 1);       
                        }
                    }
                    
                    console.log(this.activo.CodigoActivo);
                    
                }
            ).catch(error => {
                console.log(error);
            });              
        },
        getReparticionUpdate() {           
            this.ss.listReparticion({ params: { 'UnidadAcademica': this.activo.UnidadAcademica } }).then(
                 (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                    this.getPuesto();
                    this.getPersona();
                    this.getArea();
                    this.getUnidadAcademica();
                }
            ).catch(error => {
                console.log(error);
            });
            
            this.ss.showUnidadAcademica( this.activo.UnidadAcademica ).then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademica = response.data;
                    console.log('this.unidadAcademica.NumeroUA '+ this.unidadAcademica.NumeroUA);
                   
                }
            ).catch(error => {
                console.log(error);
            });         

                        
        },
        getPuesto() {
            this.ss.listPuesto({ params: { 'UnidadAcademica': this.activo.UnidadAcademica } }).then(
                (result) => {
                    let response = result.data;
                    this.puestos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getArea() {
            this.ss.listArea({ params: { 'UnidadAcademica': this.activo.UnidadAcademica } }).then(
                (result) => {
                    let response = result.data;
                    this.areas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getGrupoActivo() {
            this.ss.listGrupoActivo().then(
                (result) => {
                    let response = result.data;
                    this.grupoActivos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstadoActivo() {
            this.ss.listEstadoActivo().then(
                (result) => {
                    let response = result.data;
                    this.estadoActivo = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getProveedor() {
            this.ss.listProveedor().then(
                (result) => {
                    let response = result.data;
                    this.proveedors = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getActivo() {
            this.ss.listActivo().then(
                (result) => {
                    let response = result.data;
                    this.lactivos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPersona() {
            this.ss.listPersona({ params: { 'Persona': this.activo.Persona } }).then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;

                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstadoAsignacion() {
            this.ss.listEstadoAsignacion().then(
                (result) => {
                    let response = result.data;
                    this.estadoasignacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newActivo() {
            this.activo = {};
            this.asignacionActivo = {};
            this.getUnidadAcademica();
            this.getGrupoActivo();
            this.getEstadoActivo();
            this.getProveedor();
            this.$refs['frm-activo'].show();
        },
        newAsignacion() {
            this.getPersona();
            this.getActivo();
            this.getReparticion();
            this.getPuesto();
            this.getArea();
            // this.getEstadoAsignacion();
            this.personaasignacionactivo = {};
            this.activo = {};
            this.personaasignacionactivo.EstadoAsignacion = 2;
            this.$refs['frm-activoAsignacion'].show();
        },
        showActivo(id) {
            this.isLoading = true;
            this.ss.showActivo(id).then(
                (result) => {
                    let response = result.data;
                    this.activo = response.data;
                    this.activo.ActaIncorporacionURL = this.activo.ActaIncorporacion;
                    
                    if (response.asignacion) {
                        this.asignacionActivo = response.asignacion;
                    } else {
                        this.asignacionActivo = {};
                    }
                    this.$refs['view-activo'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },


        editActivo() {
            this.getUnidadAcademica();
            this.getGrupoActivo();
            this.getEstadoActivo();
            this.getProveedor();
            this.$refs['frm-updateActivo'].show();
            this.$refs['view-activo'].hide();
        },
        cancelActivo() {
            if (this.activo.id) {
                this.$refs['view-activo'].show();
            }
            this.$refs['frm-activo'].hide();
        },
        verificarCodigoActivo() {
            this.ss.verificarActivo(this.activo).then(
                (result) => {
                    console.log(result);
                    let respuesta = result.data;
                    if (respuesta === '') {
                        return true;
                    }else{
                        return false;
                    }                                
                });            
        },
        saveActivo() {
            console.log('foto');
                    console.log(this.activo.ActaIncorporacion);
            this.ss.verificarActivo(this.activo).then(
                (result) => {
                    //console.log(result);
                    let respuesta = result.data;

                    
                    
                    console.log('respuesta '+respuesta);
                    if (respuesta === '') {

                        const formData = new FormData();

                        formData.append('OrdendeCompra', this.activo.OrdendeCompra);
                        formData.append('CertificacionPresupuestaria', this.activo.CertificacionPresupuestaria);
                        formData.append('Pertenece', this.activo.Pertenece);
                        formData.append('ReferenciaActivo', this.activo.ReferenciaActivo);
                        formData.append('CodigoActivo', this.activo.CodigoActivo);
                        formData.append('UnidadAcademica', this.activo.UnidadAcademica);
                        formData.append('Reparticion', this.activo.Reparticion);
                        formData.append('Persona', this.activo.Persona);
                        formData.append('EstadoActivo', this.activo.EstadoActivo);
                        formData.append('ValorActivo', this.activo.ValorActivo);
                        formData.append('FechaIncorporacion', this.activo.FechaIncorporacion);
                        formData.append('Puesto', this.activo.Puesto);
                        formData.append('Proveedor', this.activo.Proveedor);
                        formData.append('GrupoActivo', this.activo.GrupoActivo);
                        formData.append('NumeroSerie', this.activo.NumeroSerie);
                        formData.append('Area', this.activo.Area);
                        formData.append('Marca', this.activo.Marca);
                        formData.append('Modelo', this.activo.Modelo);
                        formData.append('Color', this.activo.Color);
                        formData.append('Material', this.activo.Material);
                        formData.append('TipodeBien', this.activo.TipodeBien);
                        formData.append('Garantia', this.activo.Garantia);
                        formData.append('Descripcion', this.activo.Descripcion);
                        formData.append('Medidas', this.activo.Medidas);
                        formData.append('Observacion', this.activo.Observacion);
                        formData.append('ActaIncorporacion', this.activo.ActaIncorporacion);

                        console.log(formData);

                        this.ss.storeActivo(formData).then(
                            (result) => {
                                console.log(result);
                                let response = result.data;
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correctamente se guardo el Activo',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                //this.$refs['view-consulta'].show(); //para volver al modal
                                console.log(response);
                                this.activo = response.data;
                                var today = new Date();
                                let mes;
                                if ((today.getMonth() + 1) > 9) {
                                    mes = (today.getMonth() + 1);
                                } else { mes = '0' + (today.getMonth() + 1); }
                                let Fecha = today.getFullYear() + '-' + mes + '-' + today.getDate();
            
                                if (this.asignacionActivo.Persona) {
                                    this.ss.storePersonaAsignacionActivo({ 'id': this.asignacionActivo.id, 'Persona': this.asignacionActivo.Persona, 'Activo': this.activo.id, 'EstadoAsignacion': 1, 'FechaAsignacion': Fecha }).then(
                                        (result) => {
                                            console.log(result);
                                            let response = result.data;
                                            this.$bvToast.toast(
                                                response.msg,
                                                {
                                                    title: 'Correcta Asignacion al Personal',
                                                    variant: 'success',
                                                    autoHideDelay: 5000
                                                }
                                            )
                                            console.log(response);
            
                                        })
                                        .catch((error) => {
                                            this.errorBag = error.response.data.errors;
                                            this.$bvToast.toast(
                                                'Problema al Guardar el Registro, favor verificar los Datos',
                                                {
                                                    title: 'Error',
                                                    variant: 'danger',
                                                    autoHideDelay: 5000
                                                }
                                            )
                                        });
                                }
                                this.$refs['frm-activo'].hide();
                                this.showActivo(this.activo.id);
                                this.$refs['datatable-activo'].reload();
                            })
                            .catch((error) => {
                                this.errorBag = error.response.data.errors;
                                this.$bvToast.toast(
                                    'Problema al Guardar el Registro, favor verificar los Datos',
                                    {
                                        title: 'Error',
                                        variant: 'danger',
                                        autoHideDelay: 5000
                                    }
                                )
                            });
                    }else{
                        this.$bvToast.toast(
                            'El codigo del Activo fijo se encuentra registrado',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }            
                                        
                });           
        },
        updateActivo() {
            
            this.ss.verificarActivo(this.activo).then(
                (result) => {
                    //console.log(result);
                    let respuesta = result.data;
                    
                    console.log('respuesta '+respuesta);
                    
                        this.ss.storeActivo(this.activo).then(
                            (result) => {
                                console.log(result);
                                let response = result.data;
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correctamente se guardo el Activo',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                //this.$refs['view-consulta'].show(); //para volver al modal
                                console.log(response);
                                this.activo = response.data;
                                var today = new Date();
                                let mes;
                                if ((today.getMonth() + 1) > 9) {
                                    mes = (today.getMonth() + 1);
                                } else { mes = '0' + (today.getMonth() + 1); }
                                let Fecha = today.getFullYear() + '-' + mes + '-' + today.getDate();
            
                                if (this.asignacionActivo.Persona) {
                                    this.ss.storePersonaAsignacionActivo({ 'id': this.asignacionActivo.id, 'Persona': this.asignacionActivo.Persona, 'Activo': this.activo.id, 'EstadoAsignacion': 1, 'FechaAsignacion': Fecha }).then(
                                        (result) => {
                                            console.log(result);
                                            let response = result.data;
                                            this.$bvToast.toast(
                                                response.msg,
                                                {
                                                    title: 'Correcta Asignacion al Personal',
                                                    variant: 'success',
                                                    autoHideDelay: 5000
                                                }
                                            )
                                            console.log(response);
            
                                        })
                                        .catch((error) => {
                                            this.errorBag = error.response.data.errors;
                                            this.$bvToast.toast(
                                                'Problema al Guardar el Registro, favor verificar los Datos',
                                                {
                                                    title: 'Error',
                                                    variant: 'danger',
                                                    autoHideDelay: 5000
                                                }
                                            )
                                        });
                                }
                                this.$refs['frm-updateActivo'].hide();
                                this.showActivo(this.activo.id);
                                this.$refs['datatable-activo'].reload();
                            })
                            .catch((error) => {
                                this.errorBag = error.response.data.errors;
                                this.$bvToast.toast(
                                    'Problema al Guardar el Registro, favor verificar los Datos',
                                    {
                                        title: 'Error',
                                        variant: 'danger',
                                        autoHideDelay: 5000
                                    }
                                )
                            });
                             
                                        
            });           
        },
        deleteActivo() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyActivo(this.activo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-activo'].hide();
                                this.$refs['datatable-activo'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        bajaActivo(tipo) {
            if (tipo == 0) {
                this.$swal({
                    title: "¿Esta seguro que desea dar de baja el activo?",
                    text: "Esta accion es irreversible!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete.isConfirmed) {
                            this.activo.EstadoActivo = 4;
                            this.ss.storeActivo(this.activo)
                                .then((result) => {
                                    let response = result.data;
                                    console.log(response);
                                    this.$bvToast.toast(
                                        'El activo fue dado de baja, suba su respaldo de la baja',
                                        {
                                            title: 'Activo dado de BAJA',
                                            variant: 'success',
                                            autoHideDelay: 5000
                                        }
                                    )
                                    this.$refs['frm-baja'].show();
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                        } else {
                            //swal("Your imaginary file is safe!");
                        }
                    });
            }
            if (tipo == 1) {
                this.ss.storeActivo(this.activo)
                    .then((result) => {
                        let response = result.data;
                        console.log(response);
                        this.$bvToast.toast(
                            'Respaldo actualizado',
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['frm-baja'].hide();
                        this.$refs['view-activo'].hide();
                        this.$refs['datatable-activo'].reload();
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
            if (tipo == 2) {
                this.$refs['frm-baja'].hide();
                this.$refs['view-activo'].hide();
                this.$refs['datatable-activo'].reload();
            }
        },
        showSelectedActivo(id) {
            this.ss.showActivo(id).then(
                (result) => {
                    let response = result.data;
                    this.activo = response.data;
                    if (response.asignacion) {
                        this.asignacionActivo = response.asignacion;
                    } else {
                        this.asignacionActivo = {};
                    }
                    //this.$refs['view-activo'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        savePersonaAsignacionActivo() {
            this.personaasignacionactivo.Activo = this.activo.id;
            this.personaasignacionactivo.EstadoAsignacion = 2;
            this.personaasignacionactivo.Persona=this.selectedPersona.id;
            this.ss.storePersonaAsignacionActivo(this.personaasignacionactivo).then(
                (result) => {
                    this.isLoading = true;
                    console.log(result);
                    let response = result.data;
                    if (response.success==false) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Asignacion de Activos al Personal',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Asignacion de Activos al Personal',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                    }
                    console.log(response);
                    console.log("success", response.success);

                    if (response.success) {
                        // this.nuevo = 0;
                        console.log("entra al success");
                        // this.activo.id = this.personaasignacionactivo.Activo;
                        // this.activo.CodigoActivo = this.item.CodigoActivo;
                        this.activo.Reparticion = this.selectedReparticion.id; 
                        this.activo.Puesto = this.selectedCargo.id;
                        this.activo.Area = this.selectedArea.id;
                        this.ss.updateActivo(this.activo).then(
                            (result) => {
                                console.log(result);
                                let response = result.data;
                                this.activo=response.data;
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correctamente se guardo el Activo',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                console.log(response);
                                this.cancelPersonaAsignacionActivo();

                            })
                            .catch((error) => {
                                this.errorBag = error.response.data.errors;
                                this.$bvToast.toast(
                                    'Problema al Guardar el Registro, favor verificar los Datos',
                                    {
                                        title: 'Error',
                                        variant: 'danger',
                                        autoHideDelay: 5000
                                    }
                                )
                            });
                    }
                    else {
                        console.log("no entro al if");
                    }
                    this.modalAsignar();
                    this.$refs['datatable-activo'].reload();
                    // location.reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos.',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        savePersonaTransferenciaActivo() {
            this.personaasignacionactivo.Activo = this.activo.id;
            this.personaasignacionactivo.EstadoAsignacion = 5;
            this.ss.storeTPersonaAsignacionActivo(this.personaasignacionactivo).then(
                (result) => {
                    this.isLoading = true;
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcta Asignacion al Personal',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    console.log("success", response.success);

                    if (response.success) {
                        // this.nuevo = 0;
                        console.log("entra al success");
                        // this.activo.id = this.personaasignacionactivo.Activo;
                        // this.activo.CodigoActivo = this.item.CodigoActivo; 
                        this.ss.updateActivo(this.activo).then(
                            (result) => {
                                console.log(result);
                                let response = result.data;
                                this.activo=response.data;
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correctamente se guardo el Activo',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                console.log(response);
                                this.cancelPersonaAsignacionActivo();



                            })
                            .catch((error) => {
                                this.errorBag = error.response.data.errors;
                                this.$bvToast.toast(
                                    'Problema al Guardar el Registro, favor verificar los Datos',
                                    {
                                        title: 'Error',
                                        variant: 'danger',
                                        autoHideDelay: 5000
                                    }
                                )
                            });
                    }
                    else {
                        console.log("no entro al if");
                    }
                    // location.reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos.',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        generarReporteDeActivoPorReparticoin(){
            /* let url = `${process.env.VUE_APP_MAIN_SERVICE}api/ActivoReporte/ActivosPorReparticionReport?id=${this.idReporteActivoReparticion}&ano=${this.anoReporteActivoReparticion}`;
            window.open(url, '_blank'); */

            this.ss.reporteReparticion({params: {'idReparticion': this.idReporteActivoReparticion , 'ano': this.anoReporteActivoReparticion}}).then(
                (result) => {
                    let response = result.data;
                    console.log("Resultado : ", response.data);
                    //var urlFile = 'http://localhost:8000'+response.data.url;    
                    var urlFile = 'https://activosfijosbackend.net.emi.edu.bo'+response.data.url;                
                    window.open(urlFile, "abrir");  
                }
            ).catch(error => {
                console.log(error);
            })

        },

        getPersonalReparticion(id){
            this.ss.getPersonalReparticion(id).then(
                (result) => {
                    let response = result.data;
                    this.personalRepartivion = response.data;
                    console.log(response);
                    
                    this.$refs['view-reporte-reparticion'].hide();
                    this.$refs['view-Reparticion-Personal'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        cargoDocumentoCustodia(item){
            this.ss.reporteReparticionPersonal({params: {'idReparticion': this.idReporteActivoReparticion , 'idPersona': item.id}}).then(
                (result) => {
                    let response = result.data;
                    console.log("Resultado : ", response.data);
                    //var urlFile = 'http://localhost:8000'+response.data.url;    
                    var urlFile = 'https://activosfijosbackend.net.emi.edu.bo'+response.data.url;                
                    window.open(urlFile, "abrir");  
                }
            ).catch(error => {
                console.log(error);
            })
        },

        cancelarGenerarReporteDeActivoPorReparticoin(){
            this.$refs['frm-anho-reporte-reparticion'].hide();
            this.idReporteActivoReparticion = 0;
        },

        draw() {
            window.$('.btn-datatable-Activo').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showActivo(data);
            });
        },

        drawReporteReparticion() {
            window.$('.btn-datatable-ReparticionReporte').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                if(data){
                    this.idReporteActivoReparticion = data;
                    this.$refs['frm-anho-reporte-reparticion'].show();
                }else{
                    alert('Ocurrio un error inesperado, por favor intente nuevamente');
                }
            });

            window.$('.btn-datatable-ReparticionReportePersonal').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                if(data){
                    this.idReporteActivoReparticion = data;
                    this.getPersonalReparticion(data);
                }else{
                    alert('Ocurrio un error inesperado, por favor intente nuevamente');
                }
                
            });
        },
        handleFileUpload1(event) {
            this.activo.ActaIncorporacion = event.target.files[0];
            this.activo.ActaIncorporacionURL = URL.createObjectURL(this.activo.ActaIncorporacion);
        }

    },
    watch: {
        // searchActivo(q) {
        //     this.ss.select22ActivoPersonaAsignacionActivo(q).then((res) => {
        //         if (res.data.success) {
        //             this.activosS = res.data.items;

        //         } else {
        //             this.activosS = [];
        //         }
        //     })
        // },
        /*
        selectedPersona(value){
            console.log(value);
        },
        */
        //*
        selectedPersona(value) {
            this.activo.nombrepuesto = value.nombrepuesto;
            this.activo.nombrereparticion = value.nombrereparticion;       
            //console.log(value);
        },
        
        searchPersona(q){
            this.ss.select22PersonaPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.personaS = res.data.items;
                } else {
                    this.personaS = [];
                }
            }) 
        },
        searchReparticion(q){
            this.ss.select22ReparticionPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.reparticionS= res.data.items;
                    // this.activo.Reparticion=this.reparticionS[0].id;
                    this.reparticionMulti=this.reparticionS[0].id;
                } else {
                    this.reparticionS = [];
                }
            }) 
        },
        searchCargo(q){
            this.ss.select22CargoPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.cargoS= res.data.items;
                    this.activo.Puesto=this.cargoS[0].id;
                } else {
                    this.cargoS = [];
                }
            }) 
        },
        searchArea(q){
            this.ss.select22AreaPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.areaS= res.data.items;
                    this.activo.Area=this.areaS[0].id;
                } else {
                    this.areaS = [];
                }
            }) 
        },

        
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getReparticion();
            this.getUnidadAcademica();
            //this.getPuesto();
            //this.getArea();
            
            /* this.getActivo(); */
            //this.getPersona();
        }
    }
};
