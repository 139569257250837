import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ActivoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ActivoPage",
            ss: ss,
            ajax: {
                "url": ss.indexActivosEliminados(),
                headers: ss.getToken(),
            },
           
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },

                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Académica' },
                
                // { data: 'TipodeBien', name: 'a.TipodeBien', title: 'Tipo de Bien' },
                { data: 'CodigoActivo', name: 'a.CodigoActivo', title: 'Código Activo' },
                { data: 'Descripcion', name: 'a.Descripcion', title: 'Descripcion' },
                { data: 'DeleterFullUserName', name: 'a.DeleterFullUserName', title: 'Eliminado por ', searchable: false},
                { data: 'deleted_at', name: 'a.deleted_at', title: 'Fecha Eliminacion', searchable: false},
                // { data: 'GrupoActivo', name: 'ga.GrupoActivo', title: 'Grupo Activo' },
                // { data: 'EstadoActivo', name: 'EstadoActivo', title: 'Estado Activo' , searchable: false},
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            unidadAcademicas: [],
            unidadAcademica: {},
        };
    },
    methods: {

        draw() {
            window.$('.btn-datatable-Activo-Eliminado').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                console.log(data);
            });
        },

    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            console.log('.');
        }
    }
};
