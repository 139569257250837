import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
import moment from 'moment'

export default {
    name: "PersonaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PersonaPage",
            ss: ss,
            ajax: {
                "url": ss.indexPersona(),
                data: function (d) {
                    d.Liberacion = 'Liberacion';
                },
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Académica' },
                { data: 'EstadoAsignacion', name: 'EstadoAsignacion', title: 'Estado', render: function(data,type,row){
                    let Color=row.EstadoAsignacion=='Asignado'?'green':'red';
                    return '<span class="badge" style="font-size: 15px; color:'+Color+';">'+row.EstadoAsignacion+'</span>'
                }},
                { data: 'Persona', name: 'Persona', title: 'Persona', render: function(data,type,row){
                    //return '<span class="badge" style="font-size: 12px;">'+row.UnidadAcademica+'</span>'
                    return row.Persona
                }},
                { data: 'CI', name: 'CI', title: 'CI' },
                { data: 'Celular', name: 'Celular', title: 'Celular' },
                { data: 'Rol', name: 'Rol', title: 'Rol' },
                { data: 'email', name: 'email', title: 'Email' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            unidadAcademicas: [],
            departamentos: [],
            puestos: [],
            reparticions: [],
            rols: [],
            personas: [],
            persona: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            moment: moment,
            nuevasignacion:{},
            areas:{},
            personass:{},
            activosAsignadosId: {},
            personaActivoGrupo: {},
            selectAll: false,
        };
    },
    methods: {
        toggleAllCheckboxes() {
        for (let checkbox of this.persona.referencia_asignacion) {
            checkbox.checked = this.selectAll;
        }
        },
        showCheckedItems() {
        this.checkedItems = this.checkboxes.filter(checkbox => checkbox.checked);
        },
        // 
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personass = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getArea() {
            this.ss.listArea().then(
                (result) => {
                    let response = result.data;
                    this.areas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        sincronizarRRHH(){
            this.$swal({
                title: "¿Esta seguro que desea actualizar al Personal?",
                text: "¡Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                //showCancelButton: true,
            })
                .then((sincro) => {
                    if (sincro.isConfirmed) {
                        this.ss.sincronizarPersona()
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                if ( response.success ) {
                                    this.$bvToast.toast(
                                        response.msg,
                                        {
                                            title: 'Correcto',
                                            variant: 'success',
                                            autoHideDelay: 5000
                                        }
                                    )
                                    this.$refs['datatable-persona'].reload();
                                } else {
                                    this.$bvToast.toast(
                                        'Problema al exportar los Usuarios, favor verificar los Datos',
                                        {
                                            title: 'Error',
                                            variant: 'danger',
                                            autoHideDelay: 5000
                                        }
                                    )
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getDepartamento() {
            this.ss.listDepartamento().then(
                (result) => {
                    let response = result.data;
                    this.departamentos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPuesto() {
            this.ss.listPuesto().then(
                (result) => {
                    let response = result.data;
                    this.puestos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getRol() {
            this.ss.listRol().then(
                (result) => {
                    let response = result.data;
                    this.rols = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newPersona() {
            this.persona = {};
            this.$refs['frm-persona'].show();
        },
        showPersona(id) {
            this.isLoading=true;
            this.ss.showPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.persona = response.data;
                    this.persona.referencia_asignacion.checked = false;
                    this.$refs['view-persona'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editPersona() {
            this.$refs['frm-persona'].show();
            this.$refs['view-persona'].hide();
        },
        cancelPersona() {
            if (this.persona.id) {
                this.$refs['view-persona'].show();
            }
            this.$refs['frm-persona'].hide();
        },
        savePersona() {
            this.ss.storePersona(this.persona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-persona'].hide();
                    this.$refs['datatable-persona'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePersona() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyPersona(this.persona)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-persona'].hide();
                                this.$refs['datatable-persona'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword(tipo) {
            if (tipo==0) {
                this.$swal({
                    title: "¿Esta seguro que desea liberar los activos?",
                    text: "Esta accion es irreversible!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete.isConfirmed) {
                            this.persona.referencia_asignacion.forEach(element => {
                                if (element.checked) {
                                    console.log('Value checked '+ element.checked);
                                    element.EstadoAsignacion= 6;
                                    this.ss.storePersonaAsignacionActivo(element).then(
                                        (result) => {
                                            let response = result.data;
                                            console.log(response);
                                            this.$bvToast.toast(
                                                response.msg,
                                                {
                                                    title: 'Correcto',
                                                    variant: 'success',
                                                    autoHideDelay: 5000
                                                }
                                            )
                                            this.$refs['view-persona'].hide();
                                            this.$refs['datatable-persona'].reload();
                                            location.reload();
                                        })
                                    
                                }
                            });
                            /*
                            for (let i = 0; i < this.persona.referencia_asignacion.length; i++) {
                                
                                this.persona.referencia_asignacion[i].EstadoAsignacion= 6;
                                this.ss.storePersonaAsignacionActivo(this.persona.referencia_asignacion[i]).then(
                                    (result) => {
                                        let response = result.data;
                                        console.log(response);
                                        this.$bvToast.toast(
                                            response.msg,
                                            {
                                                title: 'Correcto',
                                                variant: 'success',
                                                autoHideDelay: 5000
                                            }
                                        )
                                        this.$refs['view-persona'].hide();
                                        this.$refs['datatable-persona'].reload();
                                        location.reload();
                                    })
                                    .catch((error) => {
                                        this.errorBag = error.response.data.errors;
                                        this.$bvToast.toast(
                                            'Problema al Guardar el Registro, favor verificar los Datos',
                                            {   title: 'Error',
                                                variant: 'danger',
                                                autoHideDelay: 5000})});
                            }
                            //*/
                        } else {
                            //swal("Your imaginary file is safe!");
                        }
                    });
            } 
            if (tipo==1) {
                this.ss.storeActivo(this.activo)
                    .then((result) => {
                        let response = result.data;
                        console.log(response);
                        this.$bvToast.toast(
                            'Respaldo actualizado',
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['frm-baja'].hide();
                        this.$refs['view-activo'].hide();
                        this.$refs['datatable-activo'].reload();
                    })
                    .catch(error => {
                       console.log(error);
                    })
            } 
            if (tipo==2) {
                    this.$refs['frm-baja'].hide();
                    this.$refs['view-activo'].hide();
                    this.$refs['datatable-activo'].reload();
            } 
            

        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-persona'].show();
        },
        changePassword() {
                for (let i = 0; i < this.persona.referencia_asignacion.length; i++) {
                    //console.log(this.arrayfuncion[i]['Funcion']);
                //    this.persona.referencia_asignacion
                //console.log(i, this.persona.referencia_asignacion['Activo'],this.persona.referencia_asignacion[i].Activo,this.persona.referencia_asignacion);
                console.log(i);
                    this.nuevasignacion.Activo=this.persona.referencia_asignacion[i].Activo;
                    this.nuevasignacion.EstadoAsignacion=2;//estado asignado
                    this.ss.storePersonaAsignacionActivo(this.nuevasignacion).then(
                        (result) => {
                            //console.log("funcion resultado",result);
                            let response = result.data;
                            let Nuevo = response.data;
                            this.persona.referencia_asignacion[i].AsignacionDestino= Nuevo.id;
                            this.persona.referencia_asignacion[i].EstadoAsignacion= 5;//estado Transferido
                            this.ss.storePersonaAsignacionActivo(this.persona.referencia_asignacion[i]).then(
                                (result) => {
                                    let response = result.data;
                                    console.log(response);
                                })
                                .catch((error) => {
                                    this.errorBag = error.response.data.errors;
                                    this.$bvToast.toast(
                                        'Problema al Guardar el Registro, favor verificar los Datos',
                                        {   title: 'Error',
                                            variant: 'danger',
                                            autoHideDelay: 5000})});
                            this.persona.referencia_asignacion[i].activo.Area=this.nuevasignacion.Area;                
                            this.persona.referencia_asignacion[i].activo.Reparticion=this.nuevasignacion.Reparticion;                
                            this.persona.referencia_asignacion[i].activo.Puesto=this.nuevasignacion.Puesto;                
                            this.ss.storeActivo(this.persona.referencia_asignacion[i].activo).then(
                                                (result) => {
                                                    let response = result.data;
                                                    console.log(response);
                                                })
                                                .catch((error) => {
                                                    this.errorBag = error.response.data.errors;
                                                    this.$bvToast.toast(
                                                        'Problema al Guardar el Registro, favor verificar los Datos',
                                                        {   title: 'Error',
                                                            variant: 'danger',
                                                            autoHideDelay: 5000})});
                        })
                        .catch((error) => {
                            this.errorBag = error.response.data.errors;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos',
                                {   title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000})});
                }
                this.$bvToast.toast(
                    'Transferencia completa',
                    {
                        title: 'Correcto',
                        variant: 'success',
                        autoHideDelay: 5000
                    }
                )
                this.$refs['view-password'].hide();
                this.$refs['datatable-persona'].reload();

        },
        draw() {
            window.$('.btn-datatable-Persona').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersona(data);
            });
        },
        // Custodia de Activo
        cargoDocumentoCustodia(texto){
            this.cargo=0;
            this.seccion=0;
            //this.getCargos(vm.usuario.id);
            if (texto=='Custodia') {
                this.titulo='Acta de Asignación';
            } else {
                this.titulo='Acta de Liberación';                
            }
            this.tipo=texto;
            this.ss.urlListCargoPersona({params: {'id': this.personaasignacionactivo.Persona.id}}).then(
                (result) => {
                    let response = result.data;
                    this.cargos = response.data; 
                    this.ss.urlListAreaPersona({params: {'id': this.personaasignacionactivo.Persona.id}}).then(
                        (result) => {
                            let response = result.data;
                            this.areas = response.data; 
                            this.$refs['frm-cargo'].show();
                        }
                    ).catch(error => {
                        console.log(error);
                    })
                }
            ).catch(error => {
                console.log(error);
            })
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getUnidadAcademica();
            this.getRol();
            this.getDepartamento();
            this.getPuesto();
            this.getReparticion();
        }
    }
};
