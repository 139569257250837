import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";

window.$ = window.jQuery = require("jquery");
import moment from 'moment'

// Don't forget to include the Bootstrap CSS/SCSS files!
import 'bootstrap/scss/bootstrap.scss'


export default {
    name: "PersonaAsignacionActivoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PersonaAsignacionActivoPage",
            ss: ss,
            ajax: {
                "url": ss.indexPersonaAsignacionActivoTraferencia(),
                data: function (d) {
                    d.Transferencia = 'Transferencia';
                },
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'CodigoActivo', name: 'a.CodigoActivo', title: 'Código Activo' },
                { data: 'Descripcion', name: 'a.Descripcion', title: 'Activo' },
                { data: 'EstadoAsignacion', name: 'ea.EstadoAsignacion', title: 'Estado', render: function(data,type,row){
                    let Color=row.EstadoAsignacion=='Transferido'?'green':'red';
                    return '<span class="badge" style="font-size: 15px; color:'+Color+';">'+row.EstadoAsignacion+'</span>'
                }},
                { data: 'FechaAsignacion', name: 'pa.FechaAsignacion', title: 'Fecha Asignación' },
                { data: 'FechaTransferencia', name: 'pa.FechaTransferencia', title: 'Fecha Transferencia' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            
            
            myValue: 'Seleccione Personal',
            personaasignacionactivos: [],
            personas: [],
            lactivos: [],
            estadoasignacions: [],
            personaasignacionactivo: {},
            isLoading: false,
            errorBag: {},
            activo:{},
            activos:{},
            activosS: [],
            reparticions:{},
            puestos: {},
            areas: {},
            listadoActivos: {},
            ua: 0,
            moment: moment,
            nuevo: 0,
            placeholderActivo:'',
            searchActivo: '',
            isLoadingFile:false,
            selectedActivo: null,
            selectedPersona: 0,
            transferTo:0,
            personaActivoTranferencia: {},
            //Masivo
            personaActivoGrupo: {},
            activosAsignadosId: {},
            activosTranferidoId: {},
            checkedNames: [],
            selected: [],
            updateAnteriorPersona:{},
            selectAll: false,
            checkboxColor: 'blue'
        };
    },
    methods: {
        toggleAllCheckboxes() {
            for (let checkbox of this.activosAsignadosId) {
              checkbox.checked = this.selectAll;
            }
          },
          showCheckedItems() {
            this.checkedItems = this.checkboxes.filter(checkbox => checkbox.checked);
          },
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getActivo() {
            this.ss.listActivo().then(
                (result) => {
                    let response = result.data;
                    this.lactivos = response.data;
                    
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstadoAsignacion() {
            this.ss.listEstadoAsignacion().then(
                (result) => {
                    let response = result.data;
                    this.estadoasignacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getActivoDatos(id) {
            this.isLoading=true;
            this.ss.showActivo(id).then(
                (result) => {
                    let response = result.data;
                    this.activo = response.data;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                    this.ua=response.ua;
                    //console.log(UA);
                    if (this.ua>0) {
                        this.activo.UnidadAcademica=this.ua;
                    } 
                     //this.getPuesto();
                     //this.getPersona();
                     //this.getArea();
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPuesto() {
            this.ss.listPuesto().then(
                (result) => {
                    let response = result.data;
                    this.puestos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getArea() {
            this.ss.listArea().then(
                (result) => {
                    let response = result.data;
                    this.areas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstadoActivo() {
            this.ss.listEstadoActivo().then(
                (result) => {
                    let response = result.data;
                    this.estadoActivo = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newPersonaAsignacionActivo() {
            this.getPersona();
            this.getActivo();
            this.getEstadoAsignacion();
            this.getReparticion();
            this.getPuesto();
            this.getArea();
            this.personaasignacionactivo = {};
            this.activo = {};
            this.personaasignacionactivo.EstadoAsignacion=5;
            this.nuevo=1;
            
            this.$refs['frm-personaasignacionactivolote'].show();

        },
        
        showPersonaAsignacionActivo(id) {
            this.isLoading=true;
            this.getPersona();
            this.getActivo();
            this.getEstadoAsignacion();
            this.getReparticion();

            this.ss.showPersonaAsignacionActivo(id).then(
                (result) => {
                    let response = result.data;
                    this.personaasignacionactivo = response.data;
                    this.activo = this.personaasignacionactivo.activo;
                    this.$refs['view-personaasignacionactivo'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editPersonaAsignacionActivo() {
            this.getPuesto();
            this.getArea();
            this.$refs['frm-personaasignacionactivolote'].show();
            this.$refs['Detalles'].hide();
        },
        cancelPersonaAsignacionActivo() {
            if (this.personaasignacionactivo.id) {
                this.$refs['view-personaasignacionactivo'].show();
            }
            this.$refs['frm-personaasignacionactivolote'].hide();
        },
        cancelPersonaAsignacionActivoActa() {
            if (this.personaasignacionactivo.id) {
                this.$refs['view-personaasignacionactivo'].show();
            }
            this.$refs['frm-cargo'].hide();
        },
        
        savePersonaAsignacionActivo() {
            ///*
            this.personaasignacionactivo.Activo=this.activosTranferidoId.id;
            this.personaasignacionactivo.Persona=this.selectedPersona.id.toString();
            this.personaasignacionactivo.NuevaPersona=this.activosTranferidoId.id;
            //*/
            console.log('Old: '+this.personaasignacionactivo.Persona);
            console.log('New: '+this.personaasignacionactivo.NuevaPersona);
            
            ///*
            this.activosAsignadosId.forEach(element => {                
                    //*
                    console.log('Checked '+element.checked);
                    //console.log('languages '+ $this.languages);
                    console.log('languages '+ element.CodigoActivo);
                    if (element.checked) {
                    ///*   
                    element.Persona = this.personaasignacionactivo.NuevaPersona;
                    element.PersonaLiberacion = this.personaasignacionactivo.Persona;
                    element.FechaAsignacion = this.personaasignacionactivo.FechaAsignacion;                  
                    element.FechaTransferencia = this.personaasignacionactivo.FechaAsignacion;
                    element.DescripcionTransferencia = this.personaasignacionactivo.Descripcion;
                    element.EstadoAsignacion = 2;
                    element.EstadoAsignacionT = 2;
                    element.EstadoAsignacionPersonaLiberacion = 5;
                    //*/
                    console.log('verdadero', element);

                    this.updateAnteriorPersona.id = element.id;
                    this.updateAnteriorPersona.EstadoAsignacion = 2;
                    console.log('id del la tabla PersonaAsignacionActivo ', this.updateAnteriorPersona.id );
                    ///*
                    this.ss.storeTPersonaAsignacionActivo(element).then(
                        (result) => {
                            console.log(result);
                            let response = result.data;
                            console.log(response);
                            if(response.success){
                                this.nuevo=0;
                                this.activo.id = element.Activo;
                                
                                this.ss.updateActivo(this.activo).then(
                                    (result) => {
                                        console.log(result);
                                        let response = result.data;
                                        this.$bvToast.toast(
                                            response.msg,
                                            {
                                                title: 'Correctamente se guardo el Activo',
                                                variant: 'success',
                                                autoHideDelay: 5000
                                            }
                                        )
                                        console.log(response);
                                        this.activosAsignadosId =[];
                                        this.$refs['frm-personaasignacionactivolote'].hide();
                                        this.$refs['datatable-personaasignacionactivo'].reload();
                                    })
                                    .catch((error) => {
                                        this.errorBag = error.response.data.errors;
                                        this.$bvToast.toast(
                                            'Problema al Guardar el Registro, favor verificar los Datos',
                                            {
                                                title: 'Error',
                                                variant: 'danger',
                                                autoHideDelay: 5000
                                            }
                                        )
                                    });
                                }
                            else{
                                console.log("no entro al if");
                            }
                        });
                    //*/
                    }
                //console.log(element.CodigoActivo);
            });
           
            //location.reload();         
        },
        deletePersonaAsignacionActivo() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyPersonaAsignacionActivo(this.personaasignacionactivo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-personaasignacionactivo'].hide();
                                this.$refs['datatable-personaasignacionactivo'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        getActivosIdPerson() {
            ///*
            this.ss.getActivosIdPerson(this.personaActivoGrupo.id).then(
            //this.ss.getActivosIdPerson(id).then(
                (result) => {
                    let response = result.data;
                    // console.log(response);
                    this.activosAsignadosId = response.items;
                    this.activosAsignadosId.checked = false;
                    //console.log("activosAsignadosId id: " + this.activosAsignadosId.paaId);
                    //console.log("response id: " + response.items.id);
                }
            ).catch(error => {
                console.log(error);
            });
            //*/
        },
        // Custodia de Activo
        cargoDocumentoCustodia(texto){
            this.cargo=0;
            this.seccion=0;
            //this.getCargos(vm.usuario.id);
            if (texto=='Custodia') {
                this.titulo='Acta de Asignación';
            } else {
                this.titulo='Acta de Liberación';                
            }
            this.tipo=texto;
            this.ss.urlListCargoPersona({params: {'id': this.personaasignacionactivo.Persona.id}}).then(
                (result) => {
                    let response = result.data;
                    this.cargos = response.data; 
                    this.ss.urlListAreaPersona({params: {'id': this.personaasignacionactivo.Persona.id}}).then(
                        (result) => {
                            let response = result.data;
                            this.areas = response.data; 
                            this.$refs['frm-cargo'].show();
                        }
                    ).catch(error => {
                        console.log(error);
                    })
                }
            ).catch(error => {
                console.log(error);
            })
        },
        downloadDocumentoCustodia(text) {

            this.ss.printDocumentoCustodia({params: {'id': this.personaasignacionactivo.Persona, 'texto': text, 'cargo': this.personaasignacionactivo.activo.Puesto, 'seccion': this.personaasignacionactivo.activo.Area}}).then(
                (result) => {
                    let response = result.data;
                    console.log("Resultado : ", response.data);
                    //var urlFile = 'http://localhost:8000'+response.data.url;
                    var urlFile = 'https://activosfijosbackend.net.emi.edu.bo'+response.data.url;                    
                    //var urlFile = process.env.VUE_APP_MAIN_SERVICE + response.data.url;
                    window.open(urlFile, "abrir");  
                }
            ).catch(error => {
                console.log(error);
            })
        },

        // Asignacion Masiva Prueba
        loadFileFormat(input) {
            this.isLoadingFile = true;
            console.log('Archivo:', input);
            input = event.target;
            if (input.files && input.files[0]) {
                console.log('verificando archivo', input)
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            //this.activo.DocumentoBaja = result.data.data;
                            this.personaActivoGrupo.File = input.files[0];
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        draw() {
            window.$('.btn-datatable-PersonaAsignacionActivo').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersonaAsignacionActivo(data);
                this.getActivosIdPerson(data);
            });
        }
    },
    watch: {
        
        selectedPersona(value) {
            
            this.personaActivoGrupo.Persona = value.Persona;       
            this.personaActivoGrupo.id = value.id;
            
            console.log("select persona value",value.id);
            console.log("personaActivoGrupo id "+this.personaActivoGrupo.id);
            console.log("personaActivoGrupo "+this.personaActivoGrupo.Persona);

            this.getActivosIdPerson();        
        },
        transferTo(value) {
            
            /*
            this.getActivosIdPerson();        
            */
            this.activosTranferidoId.Persona = value.Persona;       
            this.activosTranferidoId.id = value.id;
            console.log("hi",value.id);
            console.log("transferTo "+this.activosTranferidoId.id);
            console.log("transferTo "+this.activosTranferidoId.Persona);
        },
        searchActivo(q) {
            this.ss.select22ActivoPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.activosS = res.data.items;
                                   
                } else {
                    this.activosS = [];
                }
            })
        },
        
    },
    components: {
        dataTable,
        Loader,
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            this.getPersona();
            this.getActivosIdPerson();
            this.getUnidadAcademica();
            this.getEstadoActivo();
        }
    },
    
};
