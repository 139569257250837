import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import UnidadAcademicaPage from '@/pages/UnidadAcademica/UnidadAcademicaPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import PersonaPage from '@/pages/Persona/PersonaPage.vue'
import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import TipoReportePage from '@/pages/TipoReporte/TipoReportePage.vue'
import TipoReporteVerPage from '@/pages/TipoReporteVer/TipoReporteVerPage.vue'
import DepartamentoPage from '@/pages/Departamento/DepartamentoPage.vue'
import EstadoAsignacionPage from '@/pages/EstadoAsignacion/EstadoAsignacionPage.vue'
import PuestoPage from '@/pages/Puesto/PuestoPage.vue'
import ReparticionPage from '@/pages/Reparticion/ReparticionPage.vue'
import EstadoActivoPage from '@/pages/EstadoActivo/EstadoActivoPage.vue'
import GrupoActivoPage from '@/pages/GrupoActivo/GrupoActivoPage.vue'
import ProveedorPage from '@/pages/Proveedor/ProveedorPage.vue'
import AreaPage from '@/pages/Area/AreaPage.vue'
import ActivoPage from '@/pages/Activo/ActivoPage.vue'
import PersonaAsignacionActivoPage from '@/pages/PersonaAsignacionActivo/PersonaAsignacionActivoPage.vue'
import BajaPage from '@/pages/Baja/BajaPage.vue'
import FungiblePage from '@/pages/Fungible/FungiblePage.vue'
import TransferenciaPage from '@/pages/Transferencia/TransferenciaPage.vue'
import LiberacionPage from '@/pages/Liberacion/LiberacionPage.vue'
import IndicadorPage from '@/pages/Indicador/IndicadorPage.vue'
import ActivosEliminadosPage from '@/pages/ActivosEliminados/ActivosEliminadosPage.vue'
import ActivosModificadosPage from '@/pages/ActivosModificados/ActivosModificadosPage.vue'
import PrimeraAsignacion from '@/pages/PrimeraAsignacion/PrimeraAsignacionPage.vue'
import TransferenciasPage from '@/pages/Transferencias/TransferenciasPage.vue'
import LiberacionesPage from '@/pages/Liberaciones/LiberacionesPage.vue'
import DepreciacionPage from '@/pages/Depreciacion/DepreciacionPage.vue'
import PersonaActivosPage from '@/pages/PersonaActivos/PersonaActivosPage.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'PrincipalPage',
      component: PrincipalPage
    },
    {
      path: '/UnidadAcademica',
      name: 'Unidad Academica',
      component: UnidadAcademicaPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Persona',
      name: 'Persona',
      component: PersonaPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/TipoReporte',
      name: 'TipoReporte',
      component: TipoReportePage
    },
    {
      path: '/TipoReporteVer',
      name: 'TipoReporteVer',
      component: TipoReporteVerPage
    },
    {
      path: '/Departamento',
      name: 'Departamento',
      component: DepartamentoPage
    },
    {
      path: '/EstadoAsignacion',
      name: 'EstadoAsignacion',
      component: EstadoAsignacionPage
    },
    {
      path: '/Puesto',
      name: 'Puesto',
      component: PuestoPage
    },
    {
      path: '/Reparticion',
      name: 'Reparticion',
      component: ReparticionPage
    },
    {
      path: '/EstadoActivo',
      name: 'EstadoActivo',
      component: EstadoActivoPage
    },
    {
      path: '/GrupoActivo',
      name: 'GrupoActivo',
      component: GrupoActivoPage
    },
    {
      path: '/Proveedor',
      name: 'Proveedor',
      component: ProveedorPage
    },
    {
      path: '/Area',
      name: 'Area',
      component: AreaPage
    },
    {
      path: '/Activo',
      name: 'Activo',
      component: ActivoPage
    },
    {
      path: '/Baja',
      name: 'Baja',
      component: BajaPage
    },
    {
      path: '/Fungible',
      name: 'Fungible',
      component: FungiblePage
    },
    {
      path: '/Transferencia',
      name: 'Transferencia',
      component: TransferenciaPage
    },
    {
      path: '/Liberacion',
      name: 'Liberacion',
      component: LiberacionPage
    },
    {
      path: '/PersonaAsignacionActivo',
      name: 'PersonaAsignacionActivo',
      component: PersonaAsignacionActivoPage
    },
    {
      path: '/Indicador',
      name: 'Indicador',
      component: IndicadorPage
    },
    {
      path: '/ActivosEliminados',
      name: 'ActivosEliminados',
      component: ActivosEliminadosPage
    },
    {
      path: '/ActivosModificados',
      name: 'ActivosModificados',
      component: ActivosModificadosPage
    },
    {
      path: '/PrimeraAsignacion',
      name: 'PrimeraAsignacion',
      component: PrimeraAsignacion
    },
    {
      path: '/Transferencias',
      name: 'Transferencias',
      component: TransferenciasPage
    },
    {
      path: '/Liberaciones',
      name: 'Liberaciones',
      component: LiberacionesPage
    },
    {
      path: '/Depreciacion',
      name: 'Depreciacion',
      component: DepreciacionPage
    },
    {
      path: '/PersonaActivos',
      name: 'PersonaActivos',
      component: PersonaActivosPage
    },
  ]
})