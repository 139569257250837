import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
import moment from 'moment'

// Don't forget to include the Bootstrap CSS/SCSS files!
import 'bootstrap/scss/bootstrap.scss'

export default {
    name: "PersonaAsignacionActivoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PersonaAsignacionActivoPage",
            ss: ss,
            ajax: {
                "url": ss.indexPersonaAsignacionActivo(),
                data: function (d) {
                    d.PersonaAsignacionActivo = 'PersonaAsignacionActivo';
                },
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'Persona', title: 'Persona' },
                { data: 'CodigoActivo', name: 'CodigoActivo', title: 'Código Activo' },
                { data: 'Descripcion', name: 'Descripcion', title: 'Activo' },
                { data: 'EstadoAsignacion', name: 'EstadoAsignacion', title: 'Estado' },
                { data: 'FechaAsignacion', name: 'FechaAsignacion', title: 'Fecha Asignación' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            personaasignacionactivos: [],
            personas: [],
            lactivos: [],
            estadoasignacions: [],
            personaasignacionactivo: {},
            isLoading: false,
            errorBag: {},
            activo:{},
            activos:{},
            activosS: [],
            unidadAcademicas: [],
            reparticions:{},
            puestos: {},
            areas: {},
            ua: 0,
            moment: moment,
            nuevo: 0,
            placeholderActivo:'Ingrese el codigo del Activo',
            searchActivo: '',
            isLoadingFile:false,
            selectedActivo: null,
            estadoActivo:[],
            //Masivo
            personaActivoGrupo: {},
            multiselect: [],
            //search persona
            personaS: [],
            searchPersona: '',
            placeholderPersona: '',
            selectedPersona: null,
            //search Reparticion
            reparticionS: [],
            searchReparticion: '',
            placeholderReparticion:'',
            selectedReparticion: '',
            //search Cargo
            cargoS: [],
            searchCargo: '',
            placeholderCargo:'',
            selectedCargo: '',
            //search Area
            areaS: [],
            searchArea: '',
            placeholderArea:'',
            selectedArea: '',
            resultado: true,
            //pproveedors
            proveedors:[],
            //grupo activos
            grupoActivos:[],
            ///buscar
            selectAll: false,
            nameActivo:null,
        };
    },
    methods: {
        toggleAllCheckboxes() {
            for (let checkbox of this.multiselect) {
              checkbox.checked = this.selectAll;
            }
        },
        showCheckedItems() {
           this.checkedItems = this.checkboxes.filter(checkbox => checkbox.checked);
        },
        addItem(val){
            const found = this.multiselect.some(el => el.CodigoActivo === val.CodigoActivo)
            if(!found) {

                console.log(val);
                this.multiselect.push(val)
                this.$refs.hola.inputValue = ''
            } else{
                this.$bvToast.toast(
                            'Ya agrego este activo.',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
            }
        },
        deleteItem(val){
            this.multiselect.splice(this.multiselect.indexOf(val),1);
        },
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getActivo() {
            this.ss.listActivo().then(
                (result) => {
                    let response = result.data;
                    this.lactivos = response.data;
                    
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstadoAsignacion() {
            this.ss.listEstadoAsignacion().then(
                (result) => {
                    let response = result.data;
                    this.estadoasignacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getGrupoActivo() {
            this.ss.listGrupoActivo().then(
                (result) => {
                    let response = result.data;
                    this.grupoActivos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstadoActivo() {
            this.ss.listEstadoActivo().then(
                (result) => {
                    let response = result.data;
                    this.estadoActivo = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getProveedor() {
            this.ss.listProveedor().then(
                (result) => {
                    let response = result.data;
                    this.proveedors = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getActivoDatos(id) {
            this.isLoading=true;
            this.ss.showActivo(id).then(
                (result) => {
                    let response = result.data;
                    this.activo = response.data;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticions = response.data;
                    this.ua=response.ua;
                    //console.log(UA);
                    if (this.ua>0) {
                        this.activo.UnidadAcademica=this.ua;
                    } 
                     //this.getPuesto();
                     //this.getPersona();
                     //this.getArea();
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPuesto() {
            this.ss.listPuesto().then(
                (result) => {
                    let response = result.data;
                    this.puestos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getArea() {
            this.ss.listArea().then(
                (result) => {
                    let response = result.data;
                    this.areas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newPersonaAsignacionActivo() {
            this.getPersona();
            this.getActivo();
            this.getEstadoAsignacion();
            this.getReparticion();
            this.getPuesto();
            this.getArea();
            this.personaasignacionactivo = {};
            this.activo = {};
            this.personaasignacionactivo.EstadoAsignacion=2;
            this.nuevo=1;
            this.$refs['frm-personaasignacionactivo'].show();
        },
        
        cancelAsignacionMasiva() {
            if (this.personaasignacionactivo.id) {
                this.$refs['view-personaasignacionactivo'].show();
            }
            this.$refs['frm-MasivoGrupo'].hide();
        },
        saveAsignacionMasiva(){
            
        },
        newGrupo(){
           
            this.getPersona();
            this.getActivo();
            this.getEstadoAsignacion();
            this.getReparticion();
            this.getPuesto();
            this.getArea();
            this.personaasignacionactivo = {};
            this.activo = {};
            this.personaasignacionactivo.EstadoAsignacion=2;
            this.nuevo=1;
            //this.$refs['activu'].select2();
            // (this.$refs['activu'])
                    //     // init select2
                    //      .select2({ data: this.lactivos })
                    //      .val(this.lactivos.CodigoActivo)
                    //      .trigger('change')
                    //      // emit event on change.
                    //      .on('change', function () {
                    //          this.$emit('input', this.lactivos.CodigoActivo)
                    //      })
            this.$refs['frm-MasivoGrupo'].show();
        },

        showPersonaAsignacionActivo(id) {
            this.isLoading=true;
            this.getPersona();
            this.getActivo();
            this.getEstadoAsignacion();
            this.getReparticion();

            this.ss.showPersonaAsignacionActivo(id).then(
                (result) => {
                    let response = result.data;
                    this.personaasignacionactivo = response.data;
                    this.activo = this.personaasignacionactivo.activo;
                    this.$refs['view-personaasignacionactivo'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editPersonaAsignacionActivo() {
            this.getPuesto();
            this.getArea();
            this.$refs['frm-personaasignacionactivo'].show();
            this.$refs['view-personaasignacionactivo'].hide();
        },
        cancelPersonaAsignacionActivo() {
            if (this.personaasignacionactivo.id) {
                this.$refs['view-personaasignacionactivo'].show();
            }
            this.$refs['frm-personaasignacionactivo'].hide();
        },
        savePersonaAsignacionActivo() {
            if(this.multiselect.length > 0){
                for (let i = 0; i < this.multiselect.length; i++) {
                    console.log('i: ', this.multiselect[i].id)
                    //
                    if (this.multiselect[i].checked) {                   
                    this.personaasignacionactivo.Activo=this.multiselect[i].id;
                    this.personaasignacionactivo.Persona=this.selectedPersona.id;
                    this.ss.storePersonaAsignacionActivo(this.personaasignacionactivo).then(
                        (result) => {
                            console.log(result);
                            let response = result.data;
                            this.$bvToast.toast(
                                response.msg,
                                {
                                    title: 'Correcta Asignacion al Personal',
                                    variant: 'success',
                                    autoHideDelay: 5000
                                }
                            )
                            console.log(response);
                            console.log("success",response.success);
                            
                            if(response.success){
                                //this.nuevo=0;

                                this.activo.id = this.multiselect[i].id;
                                this.activo.Reparticion = this.selectedReparticion.id;
                                this.activo.Puesto = this.selectedCargo.id;
                                this.activo.Area = this.selectedArea.id;
                                this.ss.updateActivo(this.activo).then(
                                    
                                    (result) => {
                                        console.log(result);
                                        let response = result.data;
                                        this.$bvToast.toast(
                                            response.msg,
                                            {
                                                title: 'Correctamente se guardo el Activo',
                                                variant: 'success',
                                                autoHideDelay: 5000
                                            }
                                        )
                                        console.log(response);
                                    })
                                    .catch((error) => {
                                        this.errorBag = error.response.data.errors;
                                        this.$bvToast.toast(
                                            'Problema al Guardar el Registro, favor verificar los Datos',
                                            {
                                                title: 'Error',
                                                variant: 'danger',
                                                autoHideDelay: 5000
                                            }
                                        )
                                    });
                                }
                                this.$refs['frm-personaasignacionactivo'].hide();
                                this.$refs['datatable-personaasignacionactivo'].reload();
                            //location.reload();
                        })
                        .catch((error) => {
                            this.errorBag = error.response.data.errors;
                            this.$bvToast.toast(
                                'Problema al Guardar el Registro, favor verificar los Datos.',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        });
                    }
                    ///    
                }
            }else{
            this.personaasignacionactivo.Activo=this.selectedActivo.id;
            this.personaasignacionactivo.Persona=this.selectedPersona.id;
            console.log("entro");

            this.ss.storePersonaAsignacionActivo(this.personaasignacionactivo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcta Asignacion al Personal',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    // this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    console.log("success",response.success);
                    
                    if(response.success){
                        this.nuevo=0;
                        this.activo.id = this.personaasignacionactivo.Activo;
                        
                        this.activo.Reparticion = this.selectedReparticion.id;
                        this.activo.Puesto = this.selectedCargo.id;
                        this.activo.Area = this.selectedArea.id;
                        this.ss.updateActivo(this.activo).then(
                            (result) => {
                                console.log(result);
                                let response = result.data;
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correctamente se guardo el Activo',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                            })
                            .catch((error) => {
                                this.errorBag = error.response.data.errors;
                                this.$bvToast.toast(
                                    'Problema al Guardar el Registro, favor verificar los Datos',
                                    {
                                        title: 'Error',
                                        variant: 'danger',
                                        autoHideDelay: 5000
                                    }
                                )
                            });
                        }
                    this.$refs['frm-personaasignacionactivo'].hide();
                    this.$refs['datatable-personaasignacionactivo'].reload();
                    //location.reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos.',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });                
            }        
        },       
        deletePersonaAsignacionActivo() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyPersonaAsignacionActivo(this.personaasignacionactivo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-personaasignacionactivo'].hide();
                                this.$refs['datatable-personaasignacionactivo'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        saveActivo() {
            this.ss.storeActivo(this.activo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correctamente se guardo el Activo',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.activo = response.data;
                    this.$refs['frm-activo'].hide();
                    this.$refs['tabla-multiselect'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        draw() {
            window.$('.btn-datatable-PersonaAsignacionActivo').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersonaAsignacionActivo(data);
            });
        },

        // Custodia de Activo
        cargoDocumentoCustodia(texto){
            this.cargo=0;
            this.seccion=0;
            //this.getCargos(vm.usuario.id);
            if (texto=='Custodia') {
                this.titulo='Acta de Asignación';
            } else {
                this.titulo='Acta de Liberación';                
            }
            this.tipo=texto;
            this.ss.urlListCargoPersona({params: {'id': this.personaasignacionactivo.Persona.id}}).then(
                (result) => {
                    let response = result.data;
                    this.cargos = response.data; 
                    this.ss.urlListAreaPersona({params: {'id': this.personaasignacionactivo.Persona.id}}).then(
                        (result) => {
                            let response = result.data;
                            this.areas = response.data; 
                            this.$refs['frm-cargo'].show();
                        }
                    ).catch(error => {
                        console.log(error);
                    })
                }
            ).catch(error => {
                console.log(error);
            })
        },
        downloadDocumentoCustodia(text) {

            this.ss.printDocumentoCustodia({params: {'id': this.personaasignacionactivo.Persona, 'texto': text, 'cargo': this.personaasignacionactivo.activo.Puesto, 'seccion': this.personaasignacionactivo.activo.Area}}).then(
                (result) => {
                    let response = result.data;
                    console.log("Resultado : ", response.data);
                    //var urlFile = 'http://localhost:8000'+response.data.url;
                    var urlFile = 'https://activosfijosbackend.net.emi.edu.bo'+response.data.url;                    
                    window.open(urlFile, "abrir");  
                }
            ).catch(error => {
                console.log(error);
            })
        },

        // Asignacion Masiva Prueba
        loadFileFormat(input) {
            this.isLoadingFile = true;
            console.log('Archivo:', input);
            input = event.target;
            if (input.files && input.files[0]) {
                console.log('verificando archivo', input)
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            //this.activo.DocumentoBaja = result.data.data;
                            this.personaActivoGrupo.File = input.files[0];
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        saveAsignacionMasivoGroup(){
            var data = new FormData();
            data.append('File', this.personaActivoGrupo.File);
            //this.ss.storePersonaAsignacionActivo(this.personaasignacionactivo).then(
            //    (result) => {             
                this.ss.urlUploadFileAsignacion(data)
                    .then( result => {
                        console.log('resultado', result);
                        if (result.data.success) {
                            if (result.data.data === null) {
                                this.$bvToast.toast(
                                    result.data.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'info',
                                        autoHideDelay: 5000
                                    }
                                )
                                // var permisoTabla = $('#permiso-table').DataTable();
                                // permisoTabla.draw();
                            } else {
                                this.$bvToast.toast(
                                    result.data.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'info',
                                        autoHideDelay: 5000
                                    }
                                )
                                // vm.errorsFileGroup = result.data.data;
                                // vm.flagPermisoGrupo = false;
                                // $('#frm-permiso').modal('hide');
                                // var permisoTabla = $('#permiso-table').DataTable();
                                // permisoTabla.draw();

                                this.swal({
                                    title: "Algunos registros no fueron almacenados correctamente!",
                                    text: "Revise antes de cargarlos",
                                    buttons: true,
                                    icon: "warning",
                                    dangerMode: true,
                                })
                                .then((willDelete) => {
                                    if (willDelete) {
                                        //this.$refs['view-activo'].hide();
                                        //this.$refs['view-consulta'].show();
                                        //$('#frm-listaErrosGroup').modal('show');
                                    } else {
                                        //$('#frm-permiso').modal('hide');
                                        //var permisoTabla = $('#permiso-table').DataTable();
                                        //permisoTabla.draw();
                                    }
                                });
                            }
                        location.reload();
                            
                        } else {
                            this.$bvToast.toast(
                                'Error cargando asignacion.',
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch( error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error cargando asignacion de los activos',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
        },

        //Asignacion Multiple
        newMultiselectActivos(){
           
            this.getPersona();
            this.getActivo();
            this.getEstadoAsignacion();
            this.getReparticion();
            this.getPuesto();
            this.getArea();
            this.personaasignacionactivo = {};
            this.activo = {};
            this.personaasignacionactivo.EstadoAsignacion=2;
            this.nuevo=1;
            //this.$refs['activu'].select2();
            // (this.$refs['activu'])
                    //     // init select2
                    //      .select2({ data: this.lactivos })
                    //      .val(this.lactivos.CodigoActivo)
                    //      .trigger('change')
                    //      // emit event on change.
                    //      .on('change', function () {
                    //          this.$emit('input', this.lactivos.CodigoActivo)
                    //      })
            this.$refs['frm-MultiselectActivo'].show();
        },
        showActivo(id) {
            this.ss.showActivo(id).then(
                (result) => {
                    let response = result.data;
                    this.activo = response.data;
                    this.$refs['frm-activo'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
    },

    watch: {
        /*
        searchActivo(q) {
            this.ss.select22ActivoPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.activosS = res.data.items;
                } else {
                    this.activosS = [];
                }
            })
        },
        //*/
        searchActivo(q) {
            this.ss.select22ActivoPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.multiselect = res.data.items;
                } else {
                    this.multiselect = [];
                }
            })
        },
        searchPersona(q){
            this.ss.select22PersonaPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.personaS = res.data.items;
                } else {
                    this.personaS = [];
                }
            }) 
        },
        searchReparticion(q){
            this.ss.select22ReparticionPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.reparticionS= res.data.items;
                } else {
                    this.reparticionS = [];
                }
            }) 
        },
        searchCargo(q){
            this.ss.select22CargoPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.cargoS= res.data.items;
                } else {
                    this.cargoS = [];
                }
            }) 
        },
        searchArea(q){
            this.ss.select22AreaPersonaAsignacionActivo(q).then((res) => {
                if (res.data.success) {
                    this.areaS= res.data.items;
                } else {
                    this.areaS = [];
                }
            }) 
        }

    },
    components: {
        dataTable,
        Loader,
        
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            this.getUnidadAcademica();
            this.getGrupoActivo();
            this.getEstadoActivo();
            this.getProveedor();
            this.getActivo();
        }
    }
};
