var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }else{
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    //Persona
    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    listPersona(item) {
        return axios.get('api/Persona/list', item);
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    sincronizarPersona() {
        return axios.get('api/Persona/sincronizar');
    }

    printDocumentoCustodia(item) {
        return axios.get('api/Persona/printDocumentoCustodia', item);
    }

    urlListCargoPersona(item) {
        return axios.get('api/Persona/listCargo', item);
    }

    urlListAreaPersona(item) {
        return axios.get('api/Persona/listArea', item);
    }

    select2Persona(item) {
        return axios.get('api/Persona/select2?q=' + item);
    }
    getPersonalReparticion(id) {
        return axios.get('api/Persona/listPersonalReparticion?idReparticion=' + id);
    }
    
    //Unidad Academica
    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    //TipoReporte
    showTipoReporte(id) {
        return axios.get('api/TipoReporte/show?id=' + id);
    }

    indexTipoReporte() {
        return axios.defaults.baseURL + 'api/TipoReporte/index';
    }

    listTipoReporte() {
        return axios.get('api/TipoReporte/list');
    }

    storeTipoReporte(item) {
        return axios.post('api/TipoReporte/store', item);
    }

    destroyTipoReporte(item) {
        return axios.post('api/TipoReporte/destroy', item);
    }

    generateTipoReporte(item) {
        return axios.post('api/TipoReporte/generate', item);
    }


    //Departamento
    showDepartamento(id) {
        return axios.get('api/Departamento/show?id=' + id);
    }

    indexDepartamento() {
        return axios.defaults.baseURL + 'api/Departamento/index';
    }

    listDepartamento() {
        return axios.get('api/Departamento/list');
    }

    storeDepartamento(item) {
        return axios.post('api/Departamento/store', item);
    }

    destroyDepartamento(item) {
        return axios.post('api/Departamento/destroy', item);
    }

    //EstadoAsignacion
    showEstadoAsignacion(id) {
        return axios.get('api/EstadoAsignacion/show?id=' + id);
    }

    indexEstadoAsignacion() {
        return axios.defaults.baseURL + 'api/EstadoAsignacion/index';
    }

    listEstadoAsignacion() {
        return axios.get('api/EstadoAsignacion/list');
    }

    storeEstadoAsignacion(item) {
        return axios.post('api/EstadoAsignacion/store', item);
    }

    destroyEstadoAsignacion(item) {
        return axios.post('api/EstadoAsignacion/destroy', item);
    }

     //Puesto
    showPuesto(id) {
        return axios.get('api/Puesto/show?id=' + id);
    }

    indexPuesto() {
        return axios.defaults.baseURL + 'api/Puesto/index';
    }

    listPuesto(item) {
        return axios.get('api/Puesto/list', item);
    }

    storePuesto(item) {
        return axios.post('api/Puesto/store', item);
    }

    destroyPuesto(item) {
        return axios.post('api/Puesto/destroy', item);
    }
    sincronizarPuesto() {
        return axios.get('api/Puesto/sincronizar');
    }

    //Reparticion
    showReparticion(id) {
        return axios.get('api/Reparticion/show?id=' + id);
    }

    indexReparticion() {
        return axios.defaults.baseURL + 'api/Reparticion/index';
    }

    indexReporteReparticion() {
        return axios.defaults.baseURL + 'api/Reparticion/indexReporteReparticion';
    }

    listReparticion(item) {
        return axios.get('api/Reparticion/list',item);
    }

    storeReparticion(item) {
        return axios.post('api/Reparticion/store', item);
    }

    destroyReparticion(item) {
        return axios.post('api/Reparticion/destroy', item);
    }

    sincronizarReparticion() {
        return axios.get('api/Reparticion/sincronizar');
    }

    //EstadoActivo
    showEstadoActivo(id) {
        return axios.get('api/EstadoActivo/show?id=' + id);
    }

    indexEstadoActivo() {
        return axios.defaults.baseURL + 'api/EstadoActivo/index';
    }

    listEstadoActivo() {
        return axios.get('api/EstadoActivo/list');
    }

    storeEstadoActivo(item) {
        return axios.post('api/EstadoActivo/store', item);
    }

    destroyEstadoActivo(item) {
        return axios.post('api/EstadoActivo/destroy', item);
    }


    //GrupoActivo
    showGrupoActivo(id) {
        return axios.get('api/GrupoActivo/show?id=' + id);
    }

    indexGrupoActivo() {
        return axios.defaults.baseURL + 'api/GrupoActivo/index';
    }

    listGrupoActivo() {
        return axios.get('api/GrupoActivo/list');
    }

    storeGrupoActivo(item) {
        return axios.post('api/GrupoActivo/store', item);
    }

    destroyGrupoActivo(item) {
        return axios.post('api/GrupoActivo/destroy', item);
    }

    //Proveedor
    showProveedor(id) {
        return axios.get('api/Proveedor/show?id=' + id);
    }

    indexProveedor() {
        return axios.defaults.baseURL + 'api/Proveedor/index';
    }

    listProveedor() {
        return axios.get('api/Proveedor/list');
    }

    storeProveedor(item) {
        return axios.post('api/Proveedor/store', item);
    }

    destroyProveedor(item) {
        return axios.post('api/Proveedor/destroy', item);
    }

    sincronizarProveedor() {
        return axios.get('api/Proveedor/sincronizar');
    }

    //Area
    showArea(id) {
        return axios.get('api/Area/show?id=' + id);
    }

    indexArea() {
        return axios.defaults.baseURL + 'api/Area/index';
    }

    listArea(item) {
        return axios.get('api/Area/list', item);
    }

    listAreaUnidadAcademica(id) {
        return axios.get('api/Area/list?UnidadAcademica='+ id);
    }

    storeArea(item) {
        return axios.post('api/Area/store', item);
    }

    destroyArea(item) {
        return axios.post('api/Area/destroy', item);
    }

    //Activo
    showActivo(id) {
        return axios.get('api/Activo/show?id=' + id);
    }

    showActivoByCodigoActivo(id) {
        return axios.get('api/Activo/getActivosByCodigoActivo?codigoActivo=' + id);
    }

    showPersonaByNombres(id) {
        return axios.get('api/Persona/getPersonaByNombres?persona=' + id);
    }

    indexActivo() {
        return axios.defaults.baseURL + 'api/Activo/index';
    }

    indexActivoPrincipal() {
        return axios.defaults.baseURL + 'api/Activo/indexPrincipal';
    }

    indexActivosEliminados() {
        return axios.defaults.baseURL + 'api/Activo/indexActivosEliminados';
    }

    indexActivosModificados1() {
        return axios.defaults.baseURL + 'api/Activo/indexActivosModificados';
    }

    listActivo() {
        return axios.get('api/Activo/list');
    }

    storeActivo(item) {
        return axios.post('api/Activo/store', item);
    }

    updateActivo(item) {
        return axios.post('api/Activo/update', item);
    }

    destroyActivo(item) {
        return axios.post('api/Activo/destroy', item);
    }

    etiquetaActivo(item) {
        return axios.post('api/Activo/etiqueta', item);
    }

    incorporacionActivo(item) {
        return axios.post('api/Activo/incorporacion', item);
    }

    verificarActivo(item) {
        return axios.post('api/Activo/verificarActivo', item);
    }

    contarActivo(id) {
        return axios.get('api/Activo/contarActivo?UnidadAcademica='+ id);
    }

    showUltimaAsignacionActivo(id) {
        return axios.get('api/Activo/showUltimaAsignacion?Activo=' + id);
    }

    reporteReparticion (item){
        return axios.post('api/Activo/ReporteReparticion', item);
    }

    reporteReparticionPersonal (item){
        return axios.post('api/Activo/reporteActivosReparticionPersonal', item);
    }
    
    // Primera Asignacion
    indexPrimeraAsignacion() {
        return axios.defaults.baseURL + 'api/PrimeraAsignacion/index';
    }
    storePrimeraAsignacion(item){
        return axios.post('api/PrimeraAsignacion/store', item);
    }
    showPrimeraAsignacion(id){
        return axios.get('api/PrimeraAsignacion/show?id=' + id);
    }
    destroyPrimeraAsignacion(item) {
        return axios.post('api/PrimeraAsignacion/destroy', item);
    }
    generateActaAsignacion (item){
        return axios.post('api/PrimeraAsignacion/ActaAsignacionGenerate', item);
    }


    //PersonaTransferenciaActivos
    indexPersonaTrasnferenciaActivos() {
        return axios.defaults.baseURL + 'api/PersonaTransferenciasActivos/index';
    }
    indexPersonaLiberacionActivos() {
        return axios.defaults.baseURL + 'api/PersonaTransferenciasActivos/indexLiberacion';
    }
    storePersonaTrasnferenciaActivos(item){
        return axios.post('api/PersonaTransferenciasActivos/store', item);
    }
    showPersonaTrasnferenciaActivosT(id){
        return axios.get('api/PersonaTransferenciasActivos/showT?id=' + id);
    }
    showPersonaTrasnferenciaActivosL(id){
        return axios.get('api/PersonaTransferenciasActivos/showL?id=' + id);
    }
    generateActaAsignacionTransferencia (item){
        return axios.post('api/PersonaTransferenciasActivos/ActaAsignacionTransferencia', item);
    }
    generateActaLiberacionTransferencia (item){
        return axios.post('api/PersonaTransferenciasActivos/ActaLiberacionTransferencia', item);
    }

    //PersonaAsignacionActivo
    showPersonaAsignacionActivo(id) {
        return axios.get('api/PersonaAsignacionActivo/show?id=' + id);
    }
    getActivosIdPersonTransferencias(item) {
        return axios.get('api/PersonaTransferenciasActivos/getActivosIdPerson?id='+ item);
    }

    indexPersonaAsignacionActivo() {
        return axios.defaults.baseURL + 'api/PersonaAsignacionActivo/index';
    }

    listPersonaAsignacionActivo() {
        return axios.get('api/PersonaAsignacionActivo/list');
    }

    storePersonaAsignacionActivo(item) {
        return axios.post('api/PersonaAsignacionActivo/store', item);
    }
    storeTPersonaAsignacionActivo(item) {
        return axios.post('api/PersonaAsignacionActivo/storet', item);
    }

    destroyPersonaAsignacionActivo(item) {
        return axios.post('api/PersonaAsignacionActivo/destroy', item);
    }
    select22ActivoPersonaAsignacionActivo(item) {
        return axios.get('api/PersonaAsignacionActivo/select22Activo?q=' + item);
    }
    select22PersonaPersonaAsignacionActivo(item) {
        return axios.get('api/PersonaAsignacionActivo/select22Persona?q=' + item);
    }
    select22ReparticionPersonaAsignacionActivo(item) {
        return axios.get('api/PersonaAsignacionActivo/select22Reparticion?q=' + item);
    }
    select22CargoPersonaAsignacionActivo(item) {
        return axios.get('api/PersonaAsignacionActivo/select22Cargo?q=' + item);
    }
    select22AreaPersonaAsignacionActivo(item) {
        return axios.get('api/PersonaAsignacionActivo/select22Area?q=' + item);
    }
    urlUploadFileAsignacion(item) {
        return axios.post('api/PersonaAsignacionActivo/storeGroup' , item);
    }
    getActivosIdPerson(item) {
        return axios.get('api/PersonaAsignacionActivo/getActivosIdPerson?id='+ item);
    }
    generateActaAsignacionActivosPersona (item){
        return axios.post('api/PersonasActivos/generateDocumentoAsignacion', item);
    }


    /// Tranferencia
    updateTransferencia(item) {
        return axios.post('api/PersonaAsignacionActivo/updateTransferencia' , item);
    }
    
    indexPersonaAsignacionActivoTraferencia() {
        return axios.defaults.baseURL + 'api/PersonaAsignacionActivo/indexT';
    }

    //DEPRECIACION ACTIVOS
    indexDepreciacionActivos() {
        return axios.defaults.baseURL + 'api/Depreciacion/index';
    }

    //PERSONAS ACTIVOS
    indexPersonasActivos() {
        return axios.defaults.baseURL + 'api/PersonasActivos/index';
    }
    showPersonaActivoDetalle(value){
        return axios.post('api/PersonasActivos/show', value);
    }
    getGestiones(){
        return axios.get('api/PersonasActivos/getGestiones');
    }
    storePersonasActivos(item){
        return axios.post('api/PersonasActivos/store', item);
    }
    verificarAsignacionActivo(item){
        return axios.post('api/PersonasActivos/verificarAsignacionActivo', item);

    }
}