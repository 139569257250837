<template>
    <v-list nav dense>
        <v-list-item-group>
            <router-link
                class="black--text text--accent-4"
                to="/"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-bookmark</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Inicio</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Persona"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Personas</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <!-- <router-link
                class="black--text text--accent-4"
                to="Profile"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Profile")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <router-link
                class="black--text text--accent-4"
                to="Activo"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-page-layout-body</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Activos</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="Fungible"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-multiplication-box</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Fungible</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="PersonaAsignacionActivo"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-box-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.PersonaAsignacionActivo")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Transferencia"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-arrow-right-bold-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Transferencia</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>


            <router-link
                class="black--text text--accent-4"
                to="PrimeraAsignacion"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-box-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("*Primera Asignacion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Transferencias"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-arrow-right-bold-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>*Transferencias</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Liberaciones"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-lock-open</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>*Liberaciones</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="PersonaActivos"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-box-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("*Asignaciones")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
            <router-link
                class="black--text text--accent-4"
                to="Liberacion"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-lock-open</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Liberación</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> 

            <router-link
                class="black--text text--accent-4"
                to="Depreciacion"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-lock-open</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Depreciación</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> 
            
            <router-link
                class="black--text text--accent-4"
                to="Baja"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-close-box-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Bajas</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="ActivosModificados"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-close-box-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Modificado</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- <router-link
                class="black--text text--accent-4"
                to="PersonaAsignacionActivo"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-feather</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Verificación</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
            <v-list-group >
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon>mdi-buffer</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Paramétricas</v-list-item-title>
                    </v-list-item-content>
                </template>
                <router-link
                    class="black--text text--accent-4"
                    to="UnidadAcademica"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-school</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.UnidadAcademica")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link
                    class="black--text text--accent-4"
                    to="Reparticion"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-sitemap</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.Reparticion")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link
                    class="black--text text--accent-4"
                    to="Area"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-checkerboard</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.Area")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                
                <router-link
                    class="black--text text--accent-4"
                    to="Rol"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-key</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.Rol")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link> 
                <router-link
                    class="black--text text--accent-4"
                    to="Puesto"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-octagram-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.Puesto")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>           
                <router-link
                    class="black--text text--accent-4"
                    to="Proveedor"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-home-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.Proveedor")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link
                    class="black--text text--accent-4"
                    to="Departamento"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-google-maps</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.Departamento")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link
                    class="black--text text--accent-4"
                    to="EstadoAsignacion"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-counter</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.EstadoAsignacion")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link
                    class="black--text text--accent-4"
                    to="EstadoActivo"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-tilde</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.EstadoActivo")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link
                    class="black--text text--accent-4"
                    to="GrupoActivo"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-hexagon-multiple</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.GrupoActivo")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link
                    class="black--text text--accent-4"
                    to="TipoReporte"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-note-text</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.TipoReporte")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
            </v-list-group>
            <router-link
                class="black--text text--accent-4"
                to="TipoReporteVer"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-file-chart</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TipoReporteVer")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>  
            <!-- <router-link
                class="black--text text--accent-4"
                to="TipoReporteVer"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-file-chart</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Gestión</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>   -->
            <!-- <router-link
                class="black--text text--accent-4"
                to="Indicador"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-file-find</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Indicadores")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
        </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    name: "MenuVuetify",
    data() {
        return {
            msg: "MenuVuetify",
        };
    },
};
</script>