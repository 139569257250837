import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "GrupoActivoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "GrupoActivoPage",
            ss: ss,
            ajax: {
                "url": ss.indexGrupoActivo(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'GrupoActivo', name: 'GrupoActivo', title: 'Grupo Activo' },
                { data: 'VidaUtil', name: 'VidaUtil', title: 'Vida Util', 
                    render:function(data, type, row){
                        return row.VidaUtil + ' años'
                    }
                },
                { data: 'Coeficiente', name: 'Coeficiente', title: 'Coeficiente',
                    render:function(data, type, row){
                        return row.Coeficiente + ' %'
                    }
                },
                { data: 'Activo', name: 'Activo', title: 'Estado', className: 'centradito', render:function(data, type, row){
                    if (!row.Activo){ return '<i class="fa fa-ban text-danger"> Inactivo</i>'; 
                    } else{ return '<i class="fa fa-check text-success"> Activo</i>';}}},
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            grupoactivos: [],
            grupoactivo: {},
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        newGrupoActivo() {
            this.grupoactivo = {};
            this.$refs['frm-grupoactivo'].show();
        },
        showGrupoActivo(id) {
            this.isLoading=true;
            this.ss.showGrupoActivo(id).then(
                (result) => {
                    let response = result.data;
                    this.grupoactivo = response.data;
                    this.$refs['view-grupoactivo'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editGrupoActivo() {
            this.$refs['frm-grupoactivo'].show();
            this.$refs['view-grupoactivo'].hide();
        },
        cancelGrupoActivo() {
            if (this.grupoactivo.id) {
                this.$refs['view-grupoactivo'].show();
            }
            this.$refs['frm-grupoactivo'].hide();
        },
        saveGrupoActivo() {
            this.ss.storeGrupoActivo(this.grupoactivo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-grupoactivo'].hide();
                    this.$refs['datatable-grupoactivo'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteGrupoActivo() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyGrupoActivo(this.grupoactivo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-grupoactivo'].hide();
                                this.$refs['datatable-grupoactivo'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-GrupoActivo').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showGrupoActivo(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }
    }
};
